import React, { useState, useEffect } from "react";
import { HiUser } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";

const init = {
    customer_name: "",
    gstin: "",
    address: "",
    email: "",
    mobile: "",
    product_name: "",
    product_description: "",
    quantity: "",
    price_unit: "",
}


const CreateInvoice = () => {


    const [totalAmount, setTotalAmount] = useState(0);
    const [initialValues, setinitialValues] = useState(init)
    const navigate = useNavigate();
    const [isToastOpen, setToastOpen] = useState(false);



    const createInvoice = async () => {
        var formdata = new FormData();
        Object.entries(initialValues).map(([key, value]) => formdata.append(key, value));
        formdata.append("token", localStorage.getItem("token"));


        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://www.scraponwheels.com/api/app_scrapper/invoice_vendor", requestOptions)
            .then(response => response.json())
            .then(result => {
               // console.log(result)
                if (result.status == 1) { 
                    // toast(result.message);
                    setinitialValues(init);
                    setTotalAmount(0) 

                    if (!isToastOpen) {
                        toast(result.message, {
                          onClose: () => setToastOpen(false),
                        });
                        setToastOpen(true);
                      }
                } else {
                    if (!isToastOpen) {
                        toast(result.message, {
                          onClose: () => setToastOpen(false),
                        });
                        setToastOpen(true);
                      }
                }
            })
            .catch(error => console.log('error', error));
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setinitialValues({
            ...initialValues,

            [name]: name === "quantity" ? +value : name === "mobile" ? +value : name === "price_unit" ? +value : value
        });
        if (name === "quantity" || name === "price_unit") {
            const quantity = parseFloat(name === "quantity" ? value : initialValues.quantity);
            const priceUnit = parseFloat(name === "price_unit" ? value : initialValues.price_unit);
            const calculatedTotal = quantity * priceUnit;
            setTotalAmount(calculatedTotal);
        }
    }

    useEffect(() => {

    }, []);

    return (
        <>
            <ToastContainer />
            <NavLink to="/Invoices"><div style={{ marginTop: "10px", }}>
                <i className="bi bi-chevron-left " style={{ color: "#44babf", fontSize: "25px", }}></i>
            </div></NavLink>
            <div className="container mt-2">



                <div className="row">
                    <div
                        className="col-lg-11 m-auto"
                        style={{ height: "75vh", overflowY: "scroll" }}
                    >
                        <div>
                            <h1
                                style={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontSize: "32px",
                                    color: "#000",
                                }}
                            >
                                Create Invoice{" "}
                            </h1>
                            <p
                                style={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                    color: "#000",
                                }}
                            >
                                Status : credited
                            </p>
                        </div>

                        <div className="col-lg-10">
                            <p
                                style={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                    color: "#9e9e9e",
                                }}
                            >
                                Enter Customer Name
                            </p>
                            <input
                                type="text"
                                name="customer_name"
                                value={initialValues?.customer_name}
                                placeholder="Ankit Dixit"
                                className="form-control w-100 py-2 px-3"
                                style={{
                                    border: "1px solid #BFBFBF",
                                    borderRadius: "5px",
                                    background: "#fff",
                                    color: "#040404",
                                    fontFamily: "Montserrat",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                }}
                                onChange={handleChange}

                            />
                        </div>

                        <div className="col-lg-10 mt-3">
                            <p
                                style={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                    color: "#9e9e9e",
                                }}
                            >
                                GSTIN / LLP Reg No. / Prop. Number 
                            </p>
                            <input
                                type="text"
                                name="gstin"
                                value={initialValues.gstin}
                                placeholder="GSTIN / LLP No. / Prop No."
                                className="form-control w-100 py-2 px-3"
                                style={{
                                    border: "1px solid #BFBFBF",
                                    borderRadius: "5px",
                                    background: "#fff",
                                    color: "#040404",
                                    fontFamily: "Montserrat",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                }}
                                onChange={handleChange}

                            />
                        </div>

                        <div className="col-lg-10 mt-3 pb-4">
                            <p
                                style={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                    color: "#9e9e9e",
                                }}
                            >
                                Enter Address{" "}
                            </p>
                            <input
                                type="text"
                                name="address"
                                value={initialValues.address}
                                placeholder="Address"
                                className="form-control w-100 py-2 px-3"
                                style={{
                                    border: "1px solid #BFBFBF",
                                    borderRadius: "5px",
                                    background: "#fff",
                                    color: "#040404",
                                    fontFamily: "Montserrat",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                }}
                                onChange={handleChange}

                            />
                        </div>

                        <div className="col-lg-10 mt-5">
                            <p
                                style={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                    color: "#9e9e9e",
                                }}
                            >
                                Enter Email
                            </p>

                            <div
                                class="input-group mb-3"
                                style={{
                                    borderRadius: "5px",
                                    border: "1px solid #bfbfbf",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    color: "#040404",
                                    display: "flex"
                                }}
                            >
                                <span
                                    class="border-0 "
                                    style={{ background: "#fff" }}
                                    id="basic-addon1"
                                >
                                    {/* <HiOutlineMail className="px-0"/> */}
                                    <input
                                        type="email"
                                        name="email"
                                        value={initialValues.email}
                                        style={{ background: "#fff", border: "none", boxShadow: "none" }}
                                        className="form-control border-0 "
                                        placeholder="NarayanMurthy@gmail.com"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        onChange={handleChange}

                                    />
                                </span>

                            </div>
                        </div>

                        <div className="col-lg-5">
                            <p
                                style={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                    color: "#9e9e9e",
                                }}
                            >
                                Enter Contact Number
                            </p>

                            <div
                                class="input-group mb-3 w-100"
                                style={{
                                    borderRadius: "5px",
                                    border: "1px solid #bfbfbf",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    color: "#040404",
                                }}
                            >
                                <span
                                    class=" border-0"
                                    style={{ background: "#fff" }}
                                    id="basic-addon1"
                                >
                                    {/* <BsTelephone /> */}
                                    <input
                                        name="mobile"
                                        type="number"
                                        value={initialValues.mobile}
                                        style={{ background: "#fff", border: "none", boxShadow: "none" }}
                                        class="form-control border-0"
                                        placeholder="9251466327"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        onChange={handleChange}

                                    />
                                </span>

                            </div>
                        </div>

                        <div className="col-lg-10 mt-4">
                            <h1
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "32px",
                                    color: "#000",
                                }}
                            >
                                Product Details
                            </h1>
                            <input
                                type="text"
                                name="product_name"
                                value={initialValues.product_name}
                                placeholder="Product Name"
                                className="form-control w-100 py-2 px-3"
                                style={{
                                    border: "1px solid #BFBFBF",
                                    borderRadius: "5px",
                                    background: "#fff",
                                    color: "#040404",
                                    fontFamily: "Montserrat",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                }}
                                onChange={handleChange}

                            />
                        </div>

                        <div
                            className="col-lg-10 mt-3"
                            style={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontSize: "14px",
                                color: "#000",
                            }}
                        >
                            <p>Enter Product Description (OPTIONAL)</p>
                            <textarea
                                placeholder="Enter Product Description..."
                                value={initialValues.product_description}
                                name="product_description"
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                onChange={handleChange}

                            ></textarea>
                        </div>

                        <div className="col-lg-10 mt-3">
                            <p
                                style={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                    color: "#000",
                                }}
                            >
                                Enter Qty.
                            </p>
                            <input
                                type="text"
                                name="quantity"
                                value={initialValues.quantity}
                                placeholder="Enter Quantity..."
                                className="form-control w-100 py-2 px-3"
                                style={{
                                    border: "1px solid #BFBFBF",
                                    borderRadius: "5px",
                                    background: "#fff",
                                    color: "#040404",
                                    fontFamily: "Montserrat",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                }}
                                onChange={handleChange}

                            />
                        </div>
                        <div className="col-lg-10 mt-3">
                            <p
                                style={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                    color: "#000",
                                }}
                            >
                                Enter Price/Unit
                            </p>
                            <input
                                type="text"
                                name="price_unit"
                                value={initialValues.price_unit}
                                placeholder="Enter Price Per Unit..."
                                className="form-control w-100 py-2 px-3"
                                style={{
                                    border: "1px solid #BFBFBF",
                                    borderRadius: "5px",
                                    background: "#fff",
                                    color: "#040404",
                                    fontFamily: "Montserrat",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                }}
                                onChange={handleChange}

                            />
                        </div>

                    </div>

                    <div className="col-lg-11 mt-2 py-3 d-flex justify-content-around align-items-center">
                        <div
                            className="d-flex flex-column p-2"
                            style={{ border: "1px solid #bfbfbf", borderRadius: "5px" }}
                        >
                            <h3
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "20px",
                                    color: "#000",
                                }}
                            >
                                Total Amount
                            </h3>
                            <h3
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "30px",
                                    color: "#000",
                                }}
                            >
                                ₹{totalAmount.toFixed(2)}
                            </h3>
                        </div>

                        <div>
                            <button
                                onClick={createInvoice}
                                type='submit'
                                className="Create Invoice py-2 px-5"
                                style={{
                                    background: "#FF8604",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                }}
                            >
                                Create Invoice
                            </button>
                        </div>


                    </div>
                </div>

            </div>

            {/* offcanvas end of create invoice */}
        </>
    );
};

export default CreateInvoice;