
import "../componet/Scraper.css";
import logo from "../img/2ndlogo.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ShopDeValid from './VS';


export default function ShopDe() {
  const navigate = useNavigate();

  const initialValues = {
    shop_name: "",
    udhyam_aadhar: "",
    number: "",
    gst: "",
    shop_address: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ShopDeValid,
    onSubmit: values => {
        console.log('clicked')
     
     ShopDetailsSubmit(values);
    
    //   action.resetForm();
    },
  });

//   const handleProceed = () => {
//     handleSubmit();
//   };

  const ShopDetailsSubmit = (values) => {
    console.log(values);
    var formdata = new FormData();
    Object.entries(values).map(([key, value]) => formdata.append(key, value));
    formdata.append("token", localStorage.getItem("token"));

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://www.scraponwheels.com/api/app_scrapper/vendor_shop",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          navigate("/ShopPdu");
        //   toast(result.message);
        } 
        else {
          toast(result.message);
        }
        console.log(result);
      })

      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <div className="container p-3" style={{ overflowX: "hidden" }}>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 mt-3">
            <img
              src={logo}
              className="App-logo"
              alt="logo"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-3">
            <p
              style={{
                fontSize: "26px",
                fontFamily: "Inter",
                fontWeight: "600",
              }}
            >
              Enter Shop Details{" "}
            </p>
            <div>
              <p style={{ fontSize: "14px", fontFamily: "Inter" }}>
                Registering As:{" "}
                <span style={{ color: "rgba(234, 89, 50, 1)" }}>
                  {" "}
                  Scrap Collector
                </span>{" "}
                <span
                  style={{
                    float: "right",
                    color: "#6096FF",
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontWeight: "500",
                  }}
                >
                  change
                </span>{" "}
              </p>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  {" "}
                  <i className="bi bi-piggy-bank-fill"></i> Enter Shop Name
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="name"
                  aria-describedby="emailHelp"
                  placeholder="Name..."
                   name="shop_name"
                  value={formik.values.shop_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.shop_name&& formik.touched.shop_name ? (
                  <p className="from-d">{formik.errors.shop_name}</p>
                ) : null}
              </div>
              <div className="mb-3 mt-2">
                <label for="exampleInputPassword1" className="form-label">
                  {" "}
                  <i className="bi bi-wallet-fill"></i> Enter Udhyam Aadhar No.
                </label>

                <input
                  type="text"
                  className="form-control"
                 id="Aadhar"
                  name="udhyam_aadhar"
                  placeholder="Udhyam Aadhar No."
                  value={formik.values.udhyam_aadhar}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.udhyam_aadhar && formik.touched.udhyam_aadhar ? (
                  <p className="from-d">{formik.errors.udhyam_aadhar}</p>
                ) : null}
              </div>
              <div className="mb-3 mt-2">
                <label for="exampleInputPassword1" className="form-label">
                  {" "}
                  <i className="bi bi-filter-square-fill"></i> Enter GST No..
                </label>

                <input
                  type="password"
                  className="form-control"
                  id="Gst"
                  name="gst"
                  placeholder="GST NO.."
                  value={formik.values.gst}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.gst && formik.touched.gst ? (
                  <p className="from-d">{formik.errors.gst}</p>
                ) : null}
              </div>

              <div className="mb-3 mt-2">
                <label for="exampleInputPassword1" className="form-label">
                  {" "}
                  <i className="bi bi-geo-alt-fill"></i> Enter Shop Address
                </label>

                <textarea
                  placeholder="Address..."
                  className="form-control"
                  id="address"
                  rows="3"
               name="shop_address"
                  value={formik.values.shop_address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.shop_address && formik.touched.shop_address ? (
                  <p className="from-d">{formik.errors.shop_address}</p>
                ) : null}
              </div>
              <div className="row mt-3">
          <div className="col-12 p-4">
            {/* <NavLink to="/ShopPdu"> */}
            <button
              className="Proceed mt-2"
            type='submit'
            
            >
             {" "} Proceed{" "}
              <i className="bi bi-arrow-right" style={{ fontSize: "22px" }}></i>
            </button>
            {/* </NavLink> */}
          </div>
        </div>
            </form>
          </div>
        </div>

       
      </div>
    </>
  );
}
