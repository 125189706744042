import React, { useEffect,useState } from 'react'
import Header from './Header'
import Bottombar from './Bottombar'
import '../componet/Home.css';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
// import lotti from '../img/animation_llaix5r2.json';
import lotti from '../img/data.json'
import Lottie from "lottie-react";
import { ToastContainer, toast } from 'react-toastify';

const MyPrice = () => {

const Navigate = useNavigate()

  const [card, setCard] = useState([]);
  const [card1, setCard1] = useState([]);
  const [card2, setCard2] = useState([]);
  const [card3, setCard3] = useState([]);
  const [card123, setCard123] = useState();
  const [CatId,setCatId] = useState();
  const [SubCatId,setSubCatId] = useState();
  const [Dis,setDis] = useState();
  const [unit, setunit] = useState();


  const catgApi = ()=>{
    var myHeaders = new Headers();
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch("https://www.scraponwheels.com/api/app_scrapper/category_fetch", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result);
        if(result.status=="001"){
          setCard(result.message);
        }
        else{
          console.log("error");
        } 
      })
      .catch(error => console.log('error', error));
   }


   const subcatgApi = async(id)=>{

    setCatId(id);

    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    formdata.append("category_id", id);
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
        };
    
    fetch("https://www.scraponwheels.com/api/app_scrapper/vendor_prices_show", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log("Hello Jaipur",result);
        if(result.status=="001"){
          setCard1(result.data);
        }
        else{
          console.log("error");
        } 
      })
      .catch(error => console.log('error', error));
   }

   

    const MarketAdd = async(id)=>{
      try {
         
        setCard1([]);

        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        formdata.append("subcategory_id", id);
        formdata.append("rate", unit);
        

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch("https://www.scraponwheels.com/api/app_scrapper/vendor_prices_add_update", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
          if(result.status=="001"){
              toast(result.message);
              subcatgApi(CatId);
          }
          else{
            toast(result.message);
          }
        })
          .catch(error => console.log('error', error));
      } catch (error) {
        
      }
    }
    


 

  useEffect(() => {
    catgApi();

    if(!localStorage.getItem('token')){
      Navigate('/')
    }
  }, []);
  return (
    <>
    <ToastContainer/>
    <Header />
      <div className='container' style={{width:'100%',height:'80vh',overflow:'scroll'}} >
        <div className="row mt-3">
          <div className="col-12 ">
            <h1 className='liveh1'>My Rates</h1>
          </div>

        </div>
        <div className="row">
          <div className="col-12">
            <select class="form-select form-select-lg" onChange={(e)=>{subcatgApi(e.target.value)}}>
            
                    <option selected value="">Select Category</option>
                    {
                      card?.map((res)=>{
                        return(
                          <>
                          <option value={res.id} id={res.id} key={res.id}>{res.name}</option>
                          </>
                        )
                      })
                    }
                    </select>
          
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <div class="">
                  <div value="">Select Sub-Category</div>
                    {
                      card1?.length>0?
                      card1?.map((res,key)=>{
                        return( 
                          <>
                          <div className='card mt-3' style={{border:"1px solid #ff8604"}}>
                            <div className='mx-2' style={{display:"flex"}}>
                               <p className='col-6 mt-2'>{res.subcategory_name}</p>
                               <input className='col-5 mt-2 rounded' placeholder={`${res.rate}`} type='number'  style={{height:"30px",border:"1px solid #ff8604"}} onChange={(e)=>{setunit(e.target.value)}}/>
                               <span className='col-1 mt-2 '>/{res.si_unit}</span>
                            </div>
                            <button className='mx-5 primary mt-3 mb-2 rounded' style={{height:"40px",border:"1px solid #ff8604",color:"#ff8604"}} onClick={()=>{MarketAdd(res.subcategory_id )}}>Save</button>
                          </div>
                          </>
                        )
                      })
                      :<><div>
                      <div className='d-flex' style={{justifyContent:"center",alignItems:"center"}}>
             <Lottie animationData={lotti} style={{width:"90%",height:"18rem",marginLeft:"0rem",}}/>
                        </div>
                      {/* <h6 style={{color:"red"}}>No Data Found</h6> */}
                      </div></>
                    }
            </div>
          </div>
        </div>
        
       


      
      </div>

      <Bottombar />
    </>
  )
}

export default MyPrice