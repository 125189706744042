import React, { useState } from 'react';
import '../componet/Scraper.css';
// import logo from '../img/logo.png';
import logo from '../img/2ndlogo.png'
import { NavLink, useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";
// import Bicycle from '../img/dastvin.json';
import Bicycle from '../img/data.json';
import { FiArrowRight } from 'react-icons/fi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Login = () => {

  const [data, setdata] = useState("")
  const [Loading, setLoading] = useState(false)
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const countryCodes = ['+91', '+23'];
  const [Disable, setDisable] = useState(false);
  const [Loading1, setLoading1] = useState(true);

  const handleCountryCodeChange = (event) => {
    setSelectedCountryCode(event.target.value);
  };
 const Navigate = useNavigate();

   const lOGIN = async()=>{
    setDisable(true);
    setLoading(true);
     try {
    var formdata = new FormData();
     formdata.append("mobile", data);

    var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
};

      fetch("https://www.scraponwheels.com/api/app_scrapper/vendor_login", requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result);
          if(result.status=="001"){
            Navigate("/OTPLOGIN",{mobile:data});
            localStorage.setItem("mobile",data);
            toast(result.message);
          } 
          else{
            toast(result.message);
          }
        })
        .catch(error => console.log('error', error))
        .finally(()=>{setLoading(false);setTimeout(()=>{setDisable(false)},6000)});
     } catch (error) {
        console.log();
     }
   }

  return (
    <>
    <ToastContainer />
      <div className="container" style={{ overflowX: 'hidden' }}>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 mt-3">
            <img src={logo} className="App-logo" alt="logo" style={{ width: '100%' }} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 p-3">
            <div style={{ marginTop: '-10%' }}>
              <Lottie animationData={Bicycle} style={{ width: "100%" }} />
            </div>
          </div>
        </div>

        <div className='container p-2' style={{ paddingLeft: '16px' }}>
          <div className="row">
            <p style={{ fontSize: '32px', fontWeight: '600', fontFamily: 'Inter' }}>Login</p>
            <p className='loginText'>Enter Mobile No.   </p>
        



            <div style={{ width: '100%', margin: '10px 0', backgroundColor: 'white' }}>
            <div style={{ display: 'flex' }}>
              <div>
                <select
                  value={selectedCountryCode}
                  onChange={handleCountryCodeChange}
                  style={{ fontSize: '16px',padding:'15px 5px 15px 5px',marginRight:5,borderRadius:'8px'}}
                >
                  {countryCodes.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              <input
                type="number"
                required
                pattern="[0-9]*"
                //disabled={data.length==10?true:false}
                style={{
                  flex: 1,
                  padding: '12px',
                  border: '1px solid hsl(0, 2%, 49%)',
                  backgroundColor: 'white',
                  borderRadius:'8px'
                }}
                placeholder="Phone Number"
                aria-label="Phone Number" onChange={(e)=>{setdata(e.target.value)}}
              />
            </div>
            {
              Loading1==true?<></>:<><label style={{color:"red"}}>Pls Check Your Moblie Number</label></>
            }
          </div>




          </div>
        </div>

        <div className='row'>
          <div className="col-12">
            {/* <NavLink to="/OTP"> */}
              <button className="Proceed mt-2" disabled={Disable==false?false:true} onClick={()=>{data.length==10?lOGIN():setLoading1(false)}}>{Loading==false?`Proceed`: "Loading..."}</button>

              <NavLink to='/SignUp'><button className='my-4 text-center' style={{backgroundColor:'transparent',color:'#000', border:'1px solid #000', padding:'10px', borderRadius:'8px', width:'100%'}}>SignUp</button></NavLink>
            {/* </NavLink> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
