import React, { useEffect, useState } from 'react';
import './Scraper.css';
import Bottombar from './Bottombar';
import Header from './Header';
import { NavLink, useNavigate } from 'react-router-dom';
import SuccessFull from './SuccessFull';

const Home = () => {
   
     const [Name,setName]=useState();
     const [Value,setValue]=useState();
     const [Value1,setValue1]=useState();

     const Navigate = useNavigate();

   const Api = async()=>{
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/app_scrapper/home", requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result);
        if(result.status=="001"){
           setName(result.data.name);
        }
        else if(result.status=="009"){
          Navigate("/");
          localStorage.removeItem("token");
        }
      else{

      }})
        .catch(error => console.log('error', error));
   }

  

    const DashBoard = async()=>{
       try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch("https://www.scraponwheels.com/api/app_scrapper/wallet_bal", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
            if(result.status=="001"){
              setValue(JSON.parse(result.data.wallet_bal));
            }
          })
          .catch(error => console.log('error', error));
       } catch (error) {
        
       }
    }



    const DashBoard1 = async()=>{
      try {
       var formdata = new FormData();
       formdata.append("token", localStorage.getItem("token"));

       var requestOptions = {
         method: 'POST',
         body: formdata,
         redirect: 'follow'
       };

       fetch("https://www.scraponwheels.com/api/app_scrapper/stock_value_show", requestOptions)
         .then(response => response.json())
         .then(result => {console.log(result);
           if(result.status=="001"){
             setValue1(JSON.parse(result.data.stock_value));
           }
         })
         .catch(error => console.log('error', error));
      } catch (error) {
       
      }
   }


     useEffect(()=>{
      Api();
      DashBoard();
      DashBoard1();
     },[])

  return (
    <>
  
            <Header />
      <div className='container-fluid' style={{width:'100%',height:'80vh',overflow:'scroll'}}>
          <div className='row'>
            <div className="col-12 mt-3">
              <h1 className='hname'>Hey {Name} !!</h1>
              <p className='Glad'>Glad to see you Back</p>
            </div>
          </div>
          <div className="row">
          <div className="col-lg-12 py-2">
            <div className="card" style={{ width: '100%' }}>
              <div className="card-body py-4">
                <h5 className="card-title">My Collection <span style={{ color:Value>=Value1?'rgba(0, 188, 132, 1)':"red", float: 'right' }}> ₹ {Value>=Value1? `+ ${Value-Value1}`:`${Value-Value1}`}</span></h5>
                <div className="row mt-3">
                  <div className="col-6">
                    <div className='ps-2' style={{ backgroundColor: 'rgba(242, 255, 249, 1)',borderRadius:'8px' }}>
                      <div style={{ padding: '3px' }}>
                        <p className='in'>In</p>
                        <h4 className='inr'>₹ {Value}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className='ps-2' style={{ backgroundColor: 'rgba(255, 242, 242, 1)' ,borderRadius:'8px'}}>
                      <div style={{ padding: '3px' }}>
                        <p className='in1'>Out</p>
                        <h4 className='inr1'>₹ {Value1}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className='row'>
            <div className='col-12 mt-4'>
              <h2 className='quick'>Quick Actions</h2>
              <div className='row py-2'>
                <div className='col-6'>
                <NavLink to="/createInvoice">
                  <button className='btn2' style={{border:'none', borderRadius:'8px'}}> + Create Invoice</button>
                  </NavLink>
                </div>
                <div className='col-6'>
                   <NavLink to="/Inventory">
                    <button className='btn3 text-nowrap' style={{ borderRadius:'8px'}}>Manage Inventory</button>
                   </NavLink> 
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mt-4">
            <div className="col-lg-12 py-2">
              <div className="card">
                <div className="card-body py-4">
                  <h5 className="card-title">Pick-ups for Today</h5>
                  <h2>0</h2>
                  <div className="row">
                    <div className="col-6">
                      <div className=''>
                        <h6 className='complet'> <span style={{color:'green'}}>0</span> Completed</h6>
                        <h6 className='complet' > <span style={{color:'red'}}>0</span> Remaining</h6>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className='' >
                        <button className='Viewbtn'>View All</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>



         
      <Bottombar />

    </>
  )
}

export default Home;
