import React, { useState } from 'react';
import '../componet/Scraper.css';
// import logo from '../img/logo.png';
import logo from '../img/2ndlogo.png'
import {  NavLink, useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";
// import Bicycle from '../img/dastvin.json';
import Bicycle from '../img/data.json'
import { FiArrowRight } from 'react-icons/fi';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';




export default function SignUp() {
const [mobile,SetMobile] = useState('')
const navigate = useNavigate()
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const countryCodes = ['+91', '+23'];
  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(true);

  console.log("bnbnjn",Loading);

  const handleCountryCodeChange = (event) => {
    setSelectedCountryCode(event.target.value);
  };

//function for signup  
const addVender =  (mobile) => {
  setLoading(true);
var formdata = new FormData();
formdata.append("mobile", mobile);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("https://www.scraponwheels.com/api/app_scrapper/vendor_add", requestOptions)
  .then(response => response.json())
  .then(result =>{
    console.log(result,'mera OTP');
    if(result.status == 1){
      localStorage.setItem('mobile',mobile);
      navigate('/OTP')
    }
    else if(result.status=="004"){
      navigate("/Register");
      localStorage.setItem("token",result.token.token);
    }
    else if(result.status=="003"){
      navigate("/OTP");
      localStorage.setItem("token",result.token.token);
    }
    else{
      toast(result.message);
    }
    
  } )
  .catch(error => console.log('error', error))
  .finally(()=>{setTimeout(()=>{setLoading(false)},6000)});
}

  // //function for handle changes of mob no
  // const handleChange =(e)=>{
  //      const {name,value} = e.target;
  //      SetMobile(value);
  // }



  return (
    <>

      <div className="container" style={{ overflowX: 'hidden' }}>
      <ToastContainer/>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 mt-3">
            <img src={logo} className="App-logo" alt="logo" style={{ width: '100%' }} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 p-3">
            <div style={{ marginTop: '-10%' }}>
              <Lottie animationData={Bicycle} style={{ width: "100%", }} />
            </div>
          </div>
        </div>

        <div className='container p-2' style={{ paddingLeft: '16px', marginTop: "-10%" }}>
          <div class="row">
            <p style={{ fontSize: '34px', fontWeight: '600', fontFamily: 'Inter' }}>Sign Up</p>
            <p style={{ fontSize: '16px', fontWeight: '500' }}>We need to register your phone no. before getting started</p>
            <p className='loginText'>Enter Mobile No.   </p>
            <div style={{ width: '100%', margin: '10px 0', backgroundColor: 'white' }}>
              <div style={{ display: 'flex' }}>
                <div>
                  <select
                    value={selectedCountryCode}
                    onChange={handleCountryCodeChange}
                    style={{ fontSize: '16px', padding: '15px 5px 15px 5px',marginRight:5, borderRadius:'8px' }}
                  >
                    {countryCodes.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <input
                onChange={(e)=>SetMobile(e.target.value)}
                name='mobile'
                  type="number"
                  required
                  pattern="[0-9]*"
                  // disabled={mobile.length==10?true:false}
                  style={{
                    flex: 1,
                    padding: '12px',
                    border: '1px solid hsl(0, 2%, 49%)',
                    backgroundColor: 'white',
                    borderRadius:'8px'
                  }}
                  placeholder="Phone Number"
                  aria-label="Phone Number"
                />
                
                
              </div>
              {
                Loading1==true?<></>:<><label style={{color:"red"}}>Pls Check Your Moblie Number</label></>
              }
            </div>
          </div>
        </div>

       
          <button disabled={Loading==false?false:true} onClick={()=>{mobile.length==10?addVender(mobile):setLoading1(false)}} className="Proceed mt-2" >{Loading==false?`Proceed `: "Loading..."}</button>
       
          <NavLink to='/Login'><button className='mt-3' style={{border:'1px solid gray', borderRadius:'8px', padding:'12px 12px', textAlign:'center', backgroundColor:'transparent', width:'100%', fontWeight:'500', fontSize:'19px'}}> Login </button>
          </NavLink> 
      </div>

    </>
  )
}

