import * as Yup from 'yup';

const ShopDeValid = Yup.object({
//   name: Yup.string().min(2).max(25).required('Please enter your name'),
//   email: Yup.string().email().required('Please enter your email'),


//   // number: Yup.string().max(10).required('Please enter your number'),
//   // nameV: Yup.string().min(3).max(25).required('Please add the name'),

shop_name: Yup.string().max(15).required('Please add the shop Name'),
udhyam_aadhar: Yup.string().max(12).required('Please add the AadharNum'),
gst: Yup.string().max(15).required('Please add the GST number'),
shop_address: Yup.string().min(10).max(55).required('Please add the address'),

});

export default ShopDeValid;
