import React, { useState } from 'react';
import '../componet/Scraper.css';
// import logo from '../img/logo.png';
import logo from '../img/2ndlogo.png'
import { NavLink } from 'react-router-dom';
// import upm from '../img/UPM.png';
import upm from '../img/flag.png'
import UploadPhotoIcon1 from '../img/UploadPhotoIcon1.png'

export default function ShopPdu() {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    const imageUrl = URL.createObjectURL(imageFile);
    setSelectedImage(imageUrl);
  };

  const handleUploadClick = () => {
    // Perform upload logic here
    // You can send the selectedImage to your backend or perform any other required actions
    console.log('Image uploaded:', selectedImage);
  };

  return (
    <>
      <div className="container p-3" style={{ overflowX: 'hidden' }}>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 mt-2">
            <img src={logo} className="App-logo" alt="logo" style={{ width: '80%' }} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-5">
            <p style={{ fontSize: '26px', fontFamily: 'Inter', fontWeight: '500', textAlign: 'center', color: '#4B4B4B' }}>Upload Shop Photo <img src={upm} onResize='contant' style={{width:'10%'}}/> </p>
            <div>
              {selectedImage ? (
                <img src={selectedImage} alt="Selected" style={{ width: '100%',height:'20%' }} />
              ) : (
                <div className="card" style={{width:"100%",backgroundColor:"#F5F5F5",border:"1px solid #D9D9D9"}}>
                <div className="card-body">
                  <img src={UploadPhotoIcon1} alt='d' style={{display:"block",marginLeft:"auto",marginRight:"auto"}} />
                  <p className='text-center mt-2' style={{fontSize:"16px",fontWeight:"500",lineHeight:"21.81px",color:"#9B9B9B"}}>Only PNG or JPG</p>
                  <p className='text-center' style={{fontSize:"12px",lineHeight:"1px",color:"#9B9B9B"}}>Less Than 15 MB</p>
                </div>
              </div>
              )}

              <label htmlFor="imageUpload" className="Upload mt-5">
                Upload From Device <i className="bi bi-cloud-arrow-up mt-4" style={{ fontSize: '22px',paddingLeft:'7px' }}></i>
              </label>
              <label className="Camera mt-4">
              Click From Camera <i className="bi bi-camera mt-5" style={{ fontSize: '22px',paddingLeft:'7px' }}></i>
            </label>
              <input
                id="imageUpload"
                type="file"
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />

            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12 p-4">
          <NavLink to="/Congrats">
              <button className="Proceed mt-2">Continue
               <i className="bi bi-arrow-right" style={{ fontSize: '22px' }}></i></button>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
