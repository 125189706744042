import React from 'react';
import Scraper from './componet/Scraper'
import {Routes, Route } from "react-router-dom";
import Login from './componet/Login';
import SignUp from './componet/SignUp';
import OTP from './componet/OTP';
import OTPLOGIN from './componet/OTPLOGIN';
import Home from './componet/Home';
import Market from './componet/Market';
import Orders from './componet/Orders';
import Inventory from './componet/Inventory';
import Invoices from './componet/Invoices';
import Buyers from './componet/Buyers';
import OrderDetails from './componet/OrderDetails';
import { Pending } from '@mui/icons-material';
import OrderDetails1 from './componet/OrderDetails1';
import MyWallet from '../src/componet/MyWallet '; 
import SelectCategory from '../src/componet/SelectCategory';
import OtpSuccec from './componet/OtpSuccec';
import Register from './componet/Register';
import PersonalDe from './componet/PersonalDe';
import ShopDe from './componet/ShopDe';
import ShopPdu from './componet/ShopPdu';
import Congrats from './componet/Congrats';
import RemoveSelectC from '../src/componet/RemoveSelectC';
import Prices  from '../src/componet/Prices';
import Profile from './componet/Profile';
import InventoryView from './componet/InventoryView';
import CreateInvoice from './componet/CreateInvoice';
// import orderCreate from './componet/OrderCreate';
import MyPrice from "./componet/MyPrice";
import SuccessFull from './componet/SuccessFull';

function App() {
  return (
<>
<Routes>
  <Route path="/" element={<Scraper />}/>
  <Route path='Login' element={<Login />} />
  <Route path='SignUp' element={<SignUp />} />
  <Route path='OTP' element={<OTP />} />
  <Route path='OTPLOGIN' element={<OTPLOGIN />} />
  <Route path='OtpSuccec' element={<OtpSuccec />} />
  <Route path='Register' element={<Register />} />
  <Route path='PersonalDe' element={<PersonalDe />} />
  <Route path='ShopDe' element={<ShopDe />} />
  <Route path='ShopPdu' element={<ShopPdu />} />
 <Route path='Congrats' element={<Congrats />} />
  <Route path='Home' element={<Home />} />
  <Route path='Market' element={<Market />} />
  <Route path='Orders' element={<Orders />} />
  <Route path='Invoices' element={<Invoices />} />
  <Route path='Inventory' element={<Inventory />} />
  <Route path={`Markets/:id/:id1/:id2`} element={<Buyers />} />
  <Route path={`/Market/Buyers/:id/:id1/:id2`} element={<Prices/>}/>
  <Route path={`OrderDetails/:order_id`} element={<OrderDetails />} />
  <Route path='Pending' element={<Pending />} />
  <Route path='OrderDetails1/:order_id' element={<OrderDetails1 />} />
  <Route path='/Invoices/:id' element={<MyWallet />} />
  <Route path='/InventoryAdd' element={<SelectCategory />} />
  <Route path='/InventoryDelete' element={<RemoveSelectC />} />
  <Route path='/profile' element={<Profile/>}/>
  <Route path='/InventoryView' element={<InventoryView/>}/>
  <Route path='/createInvoice' element={<CreateInvoice/>}/>
  <Route path='/orderCreateSucces' element={<orderCreate/>}/>
  <Route path='/MyPrice' element={<MyPrice/>}/>
  <Route path='/SuccessFull/:add/:name' element={<SuccessFull/>}/>

</Routes>
</>
  )
}

export default App;