import React, { useEffect, useState } from 'react'
import Header from './Header';
import '../componet/Home.css'
import Bottombar from './Bottombar';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const Prices = () => {

  const Navigate = useNavigate();

    const {id,id1,id2} = useParams();
    const [card,setCard] = useState([]);
    const [kg,setKg] = useState();
    const [value,setValue]=useState();
    const [value1,setValue1]=useState();
    const [Loading,setLoading]=useState();
    const [SiUnit, setSiUnit] = useState("");


    const Api = ()=>{
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));
      formdata.append("buyer_id", id);
      formdata.append("subcategory_id", id1);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/app_scrapper/select_buyer", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log("sDFASGD SADGFB",result);
            setSiUnit(result.si_unit);
            console.log("ssrdhfg",result.si_unit);
            if(result.status=="001"){
            setCard(result.data);
            setKg(result.rate.rate);
           
            }})
        .catch(error => console.log('error', error));
    }


   const Confirm = async()=>{
    setLoading(true);
    try {
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));
      formdata.append("buyer_id", id);
      formdata.append("category_id", id2);
      formdata.append("subcategory_id", id1);
      formdata.append("rate", card[0]?.rate);
      formdata.append("weight", value1);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/app_scrapper/order_register", requestOptions)
        .then(response => response.json())
        .then(result => {console.log("ggg fff",result);
        if(result.status=="001"){
           toast(result.message);
           setTimeout(()=>{
            Navigate(`/SuccessFull/${card[0].shop_address}/${card[0].name}`)
           },3000)
        }
        else{
          toast(result.message);
        }
      })
        .catch(error => console.log('error', error)) 
        .finally(()=>{setLoading(false)});
    } catch (error) {
      console.log(error);
    }
   }

     

    useEffect(()=>{
      Api();
      if(!localStorage.getItem("token")){
        Navigate("/")
      }
      else{

      }
    },[])

  return (
    <>
    <ToastContainer/>
      <Header />
      <div className='container p-3' style={{ width: '100%', height: '80vh', overflow: 'scroll', backgroundColor: '#FBFBFB' }} >
        <div className='row'>
          <div className='col-10 p-2'>
            <h1 className='price'>Prices</h1>
            <div className='row'>
              <div className='col-1'>
                <i className="bi bi-person-fill" ></i>
              </div>
              <p className="col-10  pricetext">  <span className='ms-1'>{card[0]?.name}</span></p>
            </div>

            <div className='row'>
              <div className='col-1'>
                <i className="bi bi-geo-alt-fill"></i>
              </div>

              <p className="col-11  pricetext">  <span className='ms-1'>Location : {card[0]?.shop_address}</span></p>
            </div>

            <div className='row'>
              <div className='col-1'>
                <i className="bi bi-cash"></i>
              </div>
              <p className=" col-10 pricetext">   <span className='ms-1'>₹ {card[0]?.rate}</span> </p>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <h1 className='priceh1'>Enter Approx Weight of Scrap</h1>
          <div className="row">
            <div className="col-8">
              <input type="text" className="border px-3" placeholder="Enter Weight...." aria-label="First name"  onChange={(e)=>{setValue(e.target.value*card[0]?.rate);setValue1(e.target.value)}}/>
            </div>
            <div className="col-4">
              <button type="button" className=" Kilograms">{SiUnit} </button>
            </div>
          </div>
          <p className="mt-2">Weight need not be accurate, we just need an idea.</p>

        </div>
        <div className='row mt-2'>
          <h1 style={{ color: '#000', fontSize: '24px', fonTFamily: 'Inter' }}>Approx Scrap Value  </h1>
          <div className="col-10">
            <button type="button" className='mbutton w-100 '>{value}</button>
          </div>
          <div className="col-2 mt-3">
            <h1 style={{ color: '#858585', fontSize: '28px', fonTFamily: 'Typold' }}>Rs</h1>
          </div>
        </div>


        <div className="row mt-5 mb-5">
          <div className="col-12">
            <button className='MyScrap' onClick={()=>{Confirm()}}>{Loading==false?`Loading...`:"Sell My Scrap"}  </button>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-12">
            <p style={{ fontSize: '14px', fontFamily: 'Typold' }}>Note : By Proceeding You Accept our <span style={{ color: 'red' }}>Privacy Policy</span> and <span style={{ color: 'red' }}> Terms & Conditions</span></p>
          </div>
        </div>
      </div>
      <Bottombar />
    </>
  )
}

export default Prices;