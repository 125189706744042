import React, { useEffect, useState } from 'react';
import Header from './Header';
import Bottombar from './Bottombar';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
// import lotti from '../img/animation_llaix5r2.json';
import lotti from '../img/data.json'
import Lottie from "lottie-react";

export default function MyWallet() {

  
  const Navigate = useNavigate();
  const [card, setCard] = useState([]);
  const [TrnasId, setTrnasId] = useState();
  console.log(TrnasId);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  



  const Api = async () => {
    try {
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/app_scrapper/invoice_vendor_show", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log("", result);
          if (result.status == "001") {
            setCard(result.data);
          }
        })
        .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error);
    }
  }


  const Due = ()=>{
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    formdata.append("invoice_id", TrnasId);
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("https://www.scraponwheels.com/api/app_scrapper/invoice_vendor_due", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result)
      if(result.status=="001"){
       toast(result.message);
       Api();
      }
      else{
        toast(result.message);
      }})
      .catch(error => console.log('error', error));
   
  }


  const Paid = ()=>{
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    formdata.append("invoice_id", TrnasId);
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("https://www.scraponwheels.com/api/app_scrapper/invoice_vendor_paid", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result)
      if(result.status=="001"){
       toast(result.message);
       Api();
      }
      else{
        toast(result.message);
      }})
      .catch(error => console.log('error', error));
   
  }


  useEffect(() => {
    Api();
  }, []);

  return (
    <>
    <ToastContainer/>
      <Header />
      <div className='container p-3'
        style={{ width: '100%', height: '84vh', overflow: 'scroll', backgroundColor: '#FBFBFB' }}>
        {/*<div className='row  mt-3'>
          <div className='col-6 mt-3'>
            <h5 className='' style={{
              color: '#040404', fontSize: '17px',
              fontFamily: 'Noto Sans'
            }}> My Wallet : <b>{}</b></h5>
          </div>
          <div className='col-6'>
            <button className='DeMoney'> +  Deposit Money</button>
          </div>
          </div>*/}

        {
          card.length>0?
          card.map((ind) => {
            return (
              <>
              <div className="row mt-2 justify-content-between align-items-center"  onClick={()=>{setTrnasId(ind.id);handleShow();}} style={{borderBottom:'1px solid #DCDCDC'}}>
                  <div className="col-2">
                    <i class="bi bi-download"></i>
                  </div>
                  <div className="col-5">
                    <h5 style={{ fontSize: '16px', fontFamily: 'Noto Sans' }}>{ind.date}</h5>
                  </div>
                  <div className="col-5">
                    <p style={{ color:ind.status=="due"?"red":'#00BC84', marginLeft: '20%' }}>₹ {ind.amount}</p>
                  </div>
                </div>
              </>
            )
          }
          ):<>
          <div>
          <div className='d-flex ' style={{ justifyContent: "center", alignItems: "center" }}>
            <Lottie animationData={lotti} style={{ width: "90%", height: "18rem", marginLeft: "0rem", }} />
          </div>
          {/* <h6 style={{color:"red"}}>No Data Found</h6> */}
        </div>
          </>
        }
      </div>


      <Offcanvas show={show} onHide={handleClose} placement='bottom '>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
      <div className='row'>
      <div className='col-12'>
      <button type="button" class=" btn-primary"  data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-dismiss="offcanvas" aria-label="Close" style={{width:"100%",padding:"10px",background:"#3caa4a",fontSize:"24px",fontWeight:"500",borderRadius:"13px",border:"none",color:"#fff"}} onClick={()=>{handleClose()}}>Mark as Paid</button>
      </div>
      <div className='col-12 mt-4'>
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal1" data-bs-dismiss="offcanvas" aria-label="Close" style={{width:"100%",padding:"10px",background:"rgb(223 70 70)",fontSize:"24px",fontWeight:"500",borderRadius:"13px",border:"none",}} onClick={()=>{handleClose()}}>Mark as Due</button>
        </div>
    </div>
      </Offcanvas.Body>
    </Offcanvas>


   {/* offcanvas */}
// <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
// <div class="offcanvas-header">
//   <h5 class="offcanvas-title" id="offcanvasBottomLabel"></h5>
//   <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
// </div>
// <div class="offcanvas-body small">
// <div className='row'>
//   <div className='col-12'>
//   <button type="button" class=" btn-primary"  data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-dismiss="offcanvas" aria-label="Close" style={{width:"100%",padding:"10px",background:"#3caa4a",fontSize:"24px",fontWeight:"500",borderRadius:"13px",border:"none",color:"#fff"}}>Mark as Paid</button>
//   </div>
//   <div className='col-12 mt-4'>
//   <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal1" data-bs-dismiss="offcanvas" aria-label="Close" style={{width:"100%",padding:"10px",background:"rgb(223 70 70)",fontSize:"24px",fontWeight:"500",borderRadius:"13px",border:"none",}}>Mark as Due</button>
//     </div>
// </div>

// </div>
// </div>
{/* end offcanvas */}

{/* modal */}
{/* Button trigger modal */}
{/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
Launch demo modal
</button> */}

{/* Modal */}
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
    <div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel">Do you really want to Mark as Paid ?</h1>
   
   </div>
      <div class="modal-body">
      <div className='d-flex row'>
      <button type="button" class="btn btn-primary col-6" data-bs-dismiss="modal" onClick={()=>{Paid()}} style={{background:"#fff",color:"#000"}}>Yes</button>
      <button type="button" class="btn btn-primary col-6 "  data-bs-dismiss="modal"  style={{background:"#fff",color:"#000"}}>No</button>
      </div>
      </div>
     
    </div>
  </div>
    </div>


    <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
    <div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel">Do you really want to Mark as Due ?</h1>
   
   </div>
      <div class="modal-body">
      <div className='d-flex row'>
      <button type="button" class="btn btn-primary col-6" data-bs-dismiss="modal" onClick={()=>{Due()}} style={{background:"#fff",color:"#000"}} >Yes</button>
      <button type="button" class="btn btn-primary col-6 "  data-bs-dismiss="modal"  style={{background:"#fff",color:"#000"}}>No</button>
      </div>
      </div>
     
    </div>
  </div>
    </div>

      <Bottombar />
    </>
  )
}



