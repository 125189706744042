import React, { useEffect, useState } from 'react'
import Header from './Header'
import Bottombar from './Bottombar';
import '../componet/Home.css'
import { NavLink,useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';




const Inventory = () => {
  const navigate = useNavigate();
  const [card, setCard] = useState([]);
  const [card1, setCard1] = useState("");
  const [card2, setCard2] = useState("");
  const Apiadd = async() =>{
    try {
      var formdata = new FormData();
     formdata.append("token",  localStorage.getItem("token"));

    var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
};
      
fetch("https://www.scraponwheels.com/api/app_scrapper/get_inventory", requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result);
          if(result.status=="001"){
            setCard(result.details);
            setCard1(result.details.total_amount);
            setCard2(result. details.total_weight)
          }
          else if(result.status=="009"){
            navigate("/");
          }
         })
        .catch(error => console.log('error', error));
      
    } catch (error) {
      console.log(error);
    }
    
  }
  useEffect(()=>{
    Apiadd();
  },[])
  return (
    <>
      <Header />

      <div className='container p-3' style={{ width: '100%', height: '80vh', overflow: 'scroll', backgroundColor: '#FBFBFB' }}>
        <div className='row mt-3'>
          <div className="col-12">
            <h1 className='Inventory'>Inventory</h1>
            <p className='Stock'>Time To Stock Up!</p>
          </div>
        </div>
        <div className='row mt-3'>
          <div className="col-12">
            <div className="card p-3">
              <h3 className='cardstock'>Total Stock Value</h3>
              <h4 className='Amount mt-3'>Amount <span style={{ float: 'right', color: 'red', fontSize: '14px' }}>  ₹ {card1}</span></h4>
              <h4 className='Amount mt-3'>Weight   <span style={{ float: 'right', color: 'red', fontSize: '14px' }}> {card2} Kgs</span></h4>
               <NavLink to={`/InventoryView`} style={{ textDecoration: 'none' }}> 
                <button className='btn6 mt-4'>View Details</button>
               </NavLink> 
            </div>
          </div>
        </div>


        <div className="row mt-3">
        <div className="col-12">
          <div className='mt-2'>
            <NavLink to='/InventoryAdd' style={{ textDecoration: 'none' }}>
              <Button className='AddStock'>Add Stock</Button>
            </NavLink>
          </div>
        </div>
      </div>

        <div className="row mt-3 mb-3">
          <div className="col-12">
            <div className='mt-2'>
              <NavLink to='/InventoryDelete' style={{ textDecoration: 'none' }}>
                <Button className='Remove'>Remove Stock</Button>
              </NavLink>
            </div>
          </div>
        </div>

        <Bottombar />
      </div>
    </>
  )
}

export default Inventory