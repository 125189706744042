import React, { useEffect, useState } from 'react';
import Header from './Header';
import Bottombar from './Bottombar';
import '../componet/Home.css';
import styles from './Bottom.module.css';
import Completed from './Completed'
import Pending from './Pending'

const Orders = () => {
  const [activeTab, setActiveTab] = useState(0);


  

  return (
    <>
      <Header />

      <div className="container mt-3" >
        <div className="row p-2 d-flex">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <div className="col-6 px-2">
              <button
                className={`btn4 ${activeTab === 0 ? styles.activeS : styles.defaultS}`}
                style={{ marginRight: '1em' }}
                onClick={() => setActiveTab(0)}
              >
                Pending
              </button>
            </div>
            <div className="col-6 ms-2">
              <button
                className={`btn4  ${activeTab === 1 ? styles.activeS : styles.defaultS}`}
                style={{ marginRight: '1em' }}
                onClick={() => setActiveTab(1)}
              >
                Completed
              </button>
            </div>
          </div>
        </div>
      </div>
      {activeTab == 0 ? <><Pending /></> : <></>}
      {activeTab == 1 ? <><Completed /></> : <></>}

    
      <Bottombar />
    </>
  );
}

export default Orders;
