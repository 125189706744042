import React, { useState } from 'react';
import '../componet/Scraper.css';
// import logo from '../img/logo.png';
import logo from '../img/2ndlogo.png'
import { NavLink, useNavigate } from 'react-router-dom';
// import R1 from '../img/r1.png'
// import R2 from '../img/r2.png'
// import R3 from '../img/r3.png'
import R1 from '../img/1st.png'
 import R2 from '../img/2nd.png'
 import R3 from '../img/3rd.png'
import { ToastContainer, toast } from 'react-toastify'; 
import "../componet/Scraper.css";    
import { useFormik } from "formik";
import SingUpValid from "./V";
// import upm from '../img/UPM.png';
import upm from '../img/flag.png'

import UploadPhotoIcon1 from '../img/UploadPhotoIcon1.png';
import ShopDeValid from './VS';


export default function Register() {
const navigate = useNavigate()
    

const [count, setcount] = useState(1);
const [type, settype] = useState("");
const [Image, setImage] = useState("");
const [TypeName, setTypeName] = useState("");
const [isToastOpen, setToastOpen] = useState(false);




    const carddata = [

        {
            img: R1,
            backend_key :
            "Collectors",
            name: 'Scrap Collector',
            namep: 'If you are a scrap collector Tap Here !',
        },
        {
            img: R2,
            backend_key :
            "Wholesalers",
            name: 'Scrap Wholesaler',
            namep: 'If you are a scrap collector Tap Here !',
        },

        {
            img: R3,
            backend_key :
            "Mediators",
            name: 'Scrap Mediator',
            namep: 'If you are a scrap collector Tap Here !',
        },

        
    ]


    

    
  const initialValues = {
    name: "",
    email: "",
  };

  const initialValues1 = {
    shop_name: "",
    udhyam_aadhar: "",
    number: "",
    gst: "",
    shop_address: "",
  };


    const formik = useFormik({
        initialValues: initialValues,
    
        validationSchema: SingUpValid,
    
        onSubmit: (values) => {
          console.log("clicked in sub");
          setcount(count+1)
 
        },
      });

       
      const formik1 = useFormik({
        initialValues: initialValues1,
        validationSchema: ShopDeValid,
        onSubmit: values => {
            console.log('clicked')
            setcount(count+1)
         //ShopDetailsSubmit(values);
        
        //   action.resetForm();
        },
      });
      console.log(formik1?.values.shop_name,"hello guys")

   

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
      const imageFile = event.target.files[0];
      const imageUrl = URL.createObjectURL(imageFile);
      setSelectedImage(imageUrl);
      setImage(event.target.files[0]);
    };


    console.log('Image uploaded:', selectedImage);
  
    const handleUploadClick = () => {
      // Perform upload logic here
      // You can send the selectedImage to your backend or perform any other required actions
      console.log('Image uploaded:', selectedImage);
    };


    const Proceed = ()=>{
        var formdata = new FormData();

        formdata.append("token", localStorage.getItem("signtoken"));
        formdata.append("type", type);
        formdata.append("name",formik?.values.name);
        formdata.append("email",formik?.values.email);
        formdata.append("shop_name", formik1?.values.shop_name);
        formdata.append("udhyam_aadhar",formik1?.values.udhyam_aadhar );
        formdata.append("gst", formik1?.values.gst);
        formdata.append("shop_address", formik1?.values.shop_address);
        formdata.append("shop_photo0",  Image);

        var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
        };

        fetch("https://www.scraponwheels.com/api/app_scrapper/vendor_data", requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.status=='001'){
                console.log(result,"success run code")
                navigate("/Congrats");
            } 
            else{
              if (!isToastOpen) {
                toast(result.message, {
                  onClose: () => setToastOpen(false),
                });
                setToastOpen(true);
              }
                console.log(result,"error")
               
            }
        })
        .catch(error => console.log('error', error));
    }

    return (
        <>
        <ToastContainer/>


            <div className="container" style={{ overflowX: 'hidden' }}>

                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8 mt-3">
                        <img src={logo} className="App-logo" alt="logo" style={{ width: '100%' }} />
                    </div>

                </div>

                <div className="row mt-4">

                    <div style={{display:count===1?"block":"none"}}>
        
                    <p style={{ fontSize: '32px', fontFamily: 'Inter', fontWeight: '600' }}>Register as </p>
                    {
                        carddata?.map((itme) => {
                            return (
                                <>
                                    <div onClick={()=>{setcount(count+1);settype(itme.backend_key);setTypeName(itme.name)}}  className="col-12">
                                    {/* <NavLink to="/PersonalDe" style={{textDecoration:'none',color:'black'}}> */}
                                        <div className="card mb-3">
                                            <div className="row p-3">
                                                <div className="col-3 mt-4">
                                                    <img src={itme.img} className="img-fluid rounded-start" alt="..." />
                                                </div>
                                                <div className="col-7">
                                                    <h5 className="" style={{ fontSize: 24, fontWeight: '700' }}>{itme.name}</h5>
                                                    <p className="" style={{ fontSize: 16 }}>{itme.namep}</p>
                                                </div>
                                                <div className="col-2 mt-5">
                                                    <i className="bi bi-arrow-right" style={{ fontSize: '27px', fontWeight: '800' }}></i>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </NavLink> */}
                                    </div>
                                </>
                            )
                        })
                    }
                    </div>



                    <div style={{display:count===2?"block":"none"}}>
                    <div className="container" style={{ overflowX: "hidden" }}>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 mt-3">
            {/* <img
              src={logo}
              className="App-logo"
              alt="logo"
              style={{ width: "100%" }}
            /> */}
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-4">
            <p
              style={{
                fontSize: "26px",
                fontFamily: "Inter",
                fontWeight: "600",
              }}
            >
              Enter Personal Details
            </p>
            <div>
              <p style={{ fontSize: "14px", fontFamily: "Inter" }}>
                Registering As:
                <span style={{ color: "rgba(234, 89, 50, 1)" }}>
                  
                  {TypeName}
                </span>
                <span
                  style={{
                    float: "right",
                    color: "#6096FF",
                    fontSize: "16px",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    textDecoration:'none ',
                    cursor:'pointer'
                  }} onClick={()=>{setcount(count-1)}}
                >
                  change
                </span>
              </p>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  <i className="bi bi-person-fill"></i> Enter Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name..."
                  autoComplete="off"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.name && formik.touched.name ? (
                  <p className="form-d">{formik.errors.name}</p>
                ) : null}
              </div>
              <div className="mb-3 mt-5">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  
                  <i className="bi bi-envelope-fill"></i> Enter Email
                </label>

                <input
                  type="Email"
                  className="form-control"
                  id="email"
                  placeholder="Email..."
                  autoComplete="off"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email ? (
                  <p className="form-d">{formik.errors.email}</p>
                ) : null}
              </div>
             
              <div className="form-group">
                {/* <NavLink to='/ShopDe'> */}
                <button className="Proceed mt-2 btn-block" type="submit" >
                  
                  Proceed
                  <i
                    className="bi bi-arrow-right"
                    style={{ fontSize: "22px" }}
                  ></i>
                </button>
                {/* </NavLink> */}
              </div>
            </form>
          </div>
        </div>
      </div>
                        
                    </div>





             {/* third step start */}
             <div style={{display:count===3?"block":"none"}}>

             <div className="container p-3" style={{ overflowX: "hidden" }}>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 mt-3">
            {/* <img
              src={logo}
              className="App-logo"
              alt="logo"
              style={{ width: "100%" }}
            /> */}
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-3">
            <p
              style={{
                fontSize: "26px",
                fontFamily: "Inter",
                fontWeight: "600",
              }}
            >
              Enter Shop Details
            </p>
            <div>
              <p style={{ fontSize: "14px", fontFamily: "Inter" }}>
                Registering As:
                <span style={{ color: "rgba(234, 89, 50, 1)" }}>
                  
                {TypeName} 
                </span>
                <span
                 style={{
                  float: "right",
                  color: "#6096FF",
                  fontSize: "16px",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  textDecoration:'none ',
                  cursor:'pointer'
                }} onClick={()=>{setcount(count-1)}}
                >
                  change
                </span>
              </p>
            </div>

            <form onSubmit={formik1.handleSubmit}>
              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  
                  <i className="bi bi-piggy-bank-fill"></i> Enter Shop Name
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="name"
                  aria-describedby="emailHelp"
                  placeholder="Name..."
                   name="shop_name"
                  value={formik1.values.shop_name}
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                />
                {formik1.errors.shop_name&& formik1.touched.shop_name ? (
                  <p className="from-d">{formik1.errors.shop_name}</p>
                ) : null}
              </div>
              <div className="mb-3 mt-2">
                <label for="exampleInputPassword1" className="form-label">
                  
                  <i className="bi bi-wallet-fill"></i> Enter Udhyam Aadhar No.
                </label>

                <input
                  type="text"
                  className="form-control"
                 id="Aadhar"
                  name="udhyam_aadhar"
                  placeholder="Udhyam Aadhar No."
                  value={formik1.values.udhyam_aadhar}
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                />
                {formik1.errors.udhyam_aadhar && formik1.touched.udhyam_aadhar ? (
                  <p className="from-d">{formik1.errors.udhyam_aadhar}</p>
                ) : null}
              </div>
              <div className="mb-3 mt-2">
                <label for="exampleInputPassword1" className="form-label">
                  
                  <i className="bi bi-filter-square-fill"></i> Enter GST No..
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="Gst"
                  name="gst"
                  placeholder="GST NO.."
                  value={formik1.values.gst}
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                />
                {formik1.errors.gst && formik1.touched.gst ? (
                  <p className="from-d">{formik1.errors.gst}</p>
                ) : null}
              </div>

              <div className="mb-3 mt-2">
                <label for="exampleInputPassword1" className="form-label">
                  
                  <i className="bi bi-geo-alt-fill"></i> Enter Shop Address
                </label>

                <textarea
                  placeholder="Address..."
                  className="form-control"
                  id="address"
                  rows="3"
               name="shop_address"
                  value={formik1.values.shop_address}
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                />
                {formik1.errors.shop_address && formik1.touched.shop_address ? (
                  <p className="from-d">{formik1.errors.shop_address}</p>
                ) : null}
              </div>
              <div className="row mt-3">
          <div className="col-12 p-4">
            {/* <NavLink to="/ShopPdu"> */}
            <button
              className="Proceed mt-2"
            type='submit'
            // onClick={()=>{setcount(count+1)}}
            
            >
              Proceed
              <i className="bi bi-arrow-right" style={{ fontSize: "22px" }}></i>
            </button>
            {/* </NavLink> */}
          </div>
        </div>
            </form>
          </div>
        </div>

       
      </div>

             </div>
             {/* third step end */}




                    {/* four step start */}

                    <div style={{ display:count=== 4?"block":"none"}}>

<div className="container p-3" style={{ overflowX: 'hidden' }}>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 mt-3">
            {/* <img src={logo} className="App-logo" alt="logo" style={{ width: '100%' }} /> */}
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-3">
            <p style={{ fontSize: '26px', fontFamily: 'Inter', fontWeight: '500', textAlign: 'center', color: '#4B4B4B' }}>Upload Shop Photo <img src={upm} onResize='contant'  style={{width:'10%'}}/> </p>
            <div>
              {selectedImage ? (
                <img src={selectedImage} alt="Selected" style={{ width: '100%',height:'20%' }} />
              ) : (
                <div className="card" style={{width:"100%",backgroundColor:"#F5F5F5",border:"1px solid #D9D9D9"}}>
                <div className="card-body">
                  <img src={UploadPhotoIcon1} alt='d' style={{display:"block",marginLeft:"auto",marginRight:"auto",  }} />
                  <p className='text-center mt-2' style={{fontSize:"16px",fontWeight:"500",lineHeight:"21.81px",color:"#9B9B9B"}}>Only PNG or JPG</p>
                  <p className='text-center' style={{fontSize:"12px",lineHeight:"1px",color:"#9B9B9B"}}>Less Than 15 MB</p>
                </div>
              </div>
              )}

              <label htmlFor="imageUpload" className="Upload mt-5">
                Upload From Device <i className="bi bi-cloud-arrow-up mt-4" style={{ fontSize: '22px',paddingLeft:'7px' }}></i>
              </label>
              {/* <label className="Camera mt-4">
              Click From Camera <i className="bi bi-camera mt-5" style={{ fontSize: '22px',paddingLeft:'7px' }}></i>
            </label> */}
              <input
                id="imageUpload"
                type="file"
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />

            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12 p-4">
          {/* <NavLink to="/Congrats"> */}
              <button className="Proceed mt-2" onClick={()=>{Proceed()}}>Continue <i className="bi bi-arrow-right" style={{ fontSize: '22px' }}></i></button>
              <button className="Proceed mt-2" onClick={()=>{setcount(count-1)}}><i className="bi bi-arrow-left" style={{ fontSize: '22px' }}></i> Previous </button>
            {/* </NavLink> */}
          </div>
        </div>
      </div>


                    </div>

                    {/* four step  end */}
   


                    </div>
                    </div>
        </>
    );
}