import React, { useState,useEffect } from 'react';
import '../componet/Scraper.css';
// import logo from '../img/logo.png';
import logo from '../img/2ndlogo.png';
import { NavLink } from 'react-router-dom';
import Lottie from "lottie-react";
// import con from '../img/upn.json';
import con from '../img/data.json'

export default function Congrats() {

  useEffect(() => {
    localStorage.removeItem("token")
  }, [])
  
  return (
    <>
      <div className="container p-3" style={{ overflowX: 'hidden',cursor:'pointer' }}>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 mt-3">
            <img src={logo} className="App-logo" alt="logo" style={{ width: '100%' }} />
          </div>
        </div>
        <div className="row">
        <div className="col-12 p-3">
          <div style={{ marginTop: '-10%' }}>
            <Lottie animationData={con} style={{ width: "100%" }} />
          </div>
        </div>
      </div>


      <div className="row">
      <div className="col-12 p-3">
        <h1 style={{textAlign:'center',fontFamily:'inter',fontWeight:'600',fontSize:32}}>Congratulations</h1>
        <p style={{textAlign:'center',fontFamily:'inter',fontWeight:'500',fontSize:18}}>Your Application Has been Successfully Submitted and It Is Under Review, We Will Get back To you in 24 - 48 Hours.</p>
      </div>
    </div>



        <div className="row mt-5">
          <div className="col-12 p-4">
            <NavLink to="/">
              <button className="Proceed mt-2">Continue <i className="bi bi-arrow-right" style={{ fontSize: '22px' }}></i></button>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
