import React,{useState, useEffect} from 'react';
import './Home.css';
// import logimg from '../img/logo.png';
import logimg from '../img/2ndlogo.png'
// import Sidebar from '../componet/Sidebar';
import Bottombar from './Bottombar';
// import user from '../img/images.png';
// import '~bootstrap-icons/font/bootstrap-icons.css';
import user from '../img/flag.png';
import '../componet/Home.css';
import { NavLink, useNavigate} from 'react-router-dom';
import { CiMenuFries } from "react-icons/ci";

const Header = () => {
  let Navigate = useNavigate();
   
  const [card,setCard] = useState();

  const Profile = async()=>{
    try {
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/app_scrapper/vendor_details", requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result);
        if(result.status=="001"){
          setCard(result.data);
        }
       
      })
        .catch(error => console.log('error', error));
    } catch (error) {
      
    }
  }
  



  const Api = ()=>{
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));

    var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
    };

    fetch("https://www.scraponwheels.com/api/admin/logout", requestOptions)
    .then(response => response.json())
    .then(result => {console.log(result);
    if(result.status=="001"){
        Navigate("/");
        localStorage.removeItem("token")
    }
else{
    Navigate("/");
    localStorage.removeItem("token")
}})
    .catch(error => {console.log('error', error); 
    Navigate("/");
    localStorage.removeItem("token")});
 }

 useEffect(()=>{
  Profile();
},[])

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className="col-12 w-100" style={{padding:0,margin:0}}>
            <nav className="navbar w-100 p-2 ">
                <div className="row w-100 justify-content-between align-items-center">
                  <div className="col-1  ">
                    <button style={{ backgroundColor: 'transparent', border: 'none',  }} type="button" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample" >
                      
                      <CiMenuFries  style={{fontSize:'25px'}}/>
                    </button>
                  </div>
                  <div className="col-8">
                    <img src={logimg} alt="Logo" className="logo" style={{width:200}}  />
                  </div>
                  <div className="col-1 " style={{marginTop:'-10px'}}>
                {/*<i className="bi bi-bell" style={{ fontSize: '28px', }}></i>
                    <div className='ms-3' style={{ width: 10, height: 10, backgroundColor: "red", 
                  borderRadius: 10,marginTop:'-35px',}} ></div>*/}
                  </div>
                </div>
            </nav>
          </div>
        </div>
      </div>

      {/* Offcanvas */}
     


      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" 
      aria-labelledby="offcanvasExampleLabel"  style={{width:'70%'}}>
      <div class="offcanvas-header w-40">
        <h1></h1>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
      <div>
  {/* <Sidebar/> */}

      <div className='container'>
            <div className="row">
              <div className="col-2"></div>
            <div className="col-10">
            <NavLink to='/Profile' style={{textDecoration:'none'}}> 
            <img src={user} style={{ width: '55%',marginTop:'-5%',borderRadius:"50%",  }}  data-bs-dismiss="offcanvas" aria-label="Close"/>
            </NavLink> 
    
            <h1 className='name'>{card?.name}</h1>
            <p className='namep'>{card?.type}</p>
          </div>
            </div>

            <div className='row'>
            <div className="col-1"></div>
              <div className="col-11">
                <div className="w3-sidebar">
                <div className="navtab" activeClassName="active" data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>{setTimeout(()=>{Navigate("/Home")},1000)}}>
                <i className="bi bi-house-door-fill" style={{color:'#EA5932'}} ></i>
                <span className="vertical-text">Home</span>
                  </div>
                  <div className="navtab" activeClassName="active" data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>{setTimeout(()=>{Navigate("/Invoices")},1000)}}>
                  <i className="bi bi-receipt" style={{color:'#EA5932'}}></i>
                  <span className="vertical-text" data-bs-dismiss="offcanvas" aria-label="Close">Invoices</span>
                  </div>
                  <div className="navtab" activeClassName="active" data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>{setTimeout(()=>{Navigate("/Orders")},1000)}}>
                  <i class="bi bi-bag-dash" style={{color:'#EA5932'}}></i>
                  <span className="vertical-text" >Orders</span>
                  </div>
                  <div className="navtab" activeClassName="active" data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>{setTimeout(()=>{Navigate("/Market")},1000)}}>
                  <i className="bi bi-shop" style={{color:'#EA5932'}}></i>
                  <span className="vertical-text">Market</span>
                  </div>
                  <div className="navtab" activeClassName="active" data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>{setTimeout(()=>{Navigate("/Inventory")},1000)}}>
                  <i className="bi bi-archive-fill" style={{color:'#EA5932'}}></i>
                  <span className="vertical-text">Inventory</span>
                  </div>
                  <div className="navtab" activeClassName="active" data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>{setTimeout(()=>{Navigate("/MyPrice")},1000)}}>
                  <i className="bi bi-shop" style={{color:'#EA5932'}}></i>
                  <span className="vertical-text">My Price</span>
                  </div>
                  <div className="navtab" activeClassName="active" data-bs-dismiss="offcanvas" aria-label="Close" >
                  <i className="bi bi-arrow-return-left" style={{color:'#EA5932'}}></i>
                  <span className="vertical-text" >Return Policy</span>
                  </div>
                  <div className="navtab" activeClassName="active" data-bs-dismiss="offcanvas" aria-label="Close" >
                  <i className="bi bi-person-fill-lock" style={{color:'#EA5932'}}></i>
                  <span className="vertical-text" >Privacy Policy</span>
                  </div>

                </div>
                <div>
                <button className='btn' type="button" data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>{Api()}}>Logout</button>
                </div>
              </div>
            </div>
          </div>
    </div>
      </div>
      </div>

    </>
  )
}

export default Header;