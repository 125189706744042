import React, { useEffect,useState } from 'react'
import Header from './Header'
import Bottombar from './Bottombar'
import '../componet/Home.css';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { NavLink,  useNavigate } from 'react-router-dom';
// import lotti from '../img/animation_llaix5r2.json';
import lotti from '../img/data.json'
import Lottie from "lottie-react";

const Market = () => {
  const Navigate = useNavigate()
  const [card, setCard] = useState([]);
  const [card1, setCard1] = useState([]);
  const [card2, setCard2] = useState([]);
  const [card3, setCard3] = useState([]);
  const [card123, setCard123] = useState();
  const [CatId,setCatId] = useState();
  const [SubCatId,setSubCatId] = useState();
  const [Dis,setDis] = useState();

  const catgApi = ()=>{
    var myHeaders = new Headers();
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch("https://www.scraponwheels.com/api/app_scrapper/category_fetch", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result);
        if(result.status=="001"){
          setCard(result.message);
        }
        else{
          console.log("error");
        } 
      })
      .catch(error => console.log('error', error));
   }


   const subcatgApi = async(id)=>{

    setCatId(id);

    var formdata = new FormData();
    formdata.append("category_id", id);
    var requestOptions = {
      method: 'POST',
  body: formdata,
  redirect: 'follow'
    };
    
    fetch("https://www.scraponwheels.com/api/app_scrapper/subcategory_fetch", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if(result.status=="001"){
          setCard1(result.message);
        }
        else{
          console.log("error");
        } 
      })
      .catch(error => console.log('error', error));
   }


   const districtApi = (id)=>{
    setSubCatId(id);

    var formdata = new FormData();
    formdata.append("subcategory_id", id);
    var requestOptions = {
      method: 'POST',
  body: formdata,
  redirect: 'follow'
    };
    
    fetch("https://www.scraponwheels.com/api/app_scrapper/district_fetch", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result);
        if(result.status=="001"){
          setCard2(result.message);
        }
        else{
          console.log("error");
        } 
      })
      .catch(error => console.log('error', error));
   }

   

    const Market = async(id)=>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        formdata.append("category_id", CatId);
        formdata.append("subcategory_id", SubCatId);
        formdata.append("district", id);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch("https://www.scraponwheels.com/api/app_scrapper/market", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
          if(result.status=="001"){
            setDis(id);
            setCard3(result.message);
            setCard123(result.subcategory.rate)
          }})
          .catch(error => console.log('error', error));
      } catch (error) {
        
      }
    }
    


 

  useEffect(() => {
    catgApi();

    if(!localStorage.getItem('token')){

      Navigate("/")
    }
    else{
    
    }
  }, []);
  return (
    <>
    <Header />
      <div className='container' style={{width:'100%',height:'80vh',overflow:'scroll'}} >
        <div className="row mt-3">
          <div className="col-12 ">
            <h1 className='liveh1'>Live Market Rates</h1>
          </div>

        </div>
        <div className="row">
          <div className="col-12">
            <select class="form-select form-select-lg" onChange={(e)=>{subcatgApi(e.target.value)}}>
            
                    <option selected value="">Select Category</option>
                    {
                      card?.map((res)=>{
                        return(
                          <>
                          <option value={res.id} id={res.id} key={res.id}>{res.name}</option>
                          </>
                        )
                      })
                    }
                    </select>
          
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <select class="form-select form-select-lg" onChange={(e)=>{districtApi(e.target.value)}}>
                  <option selected value="">Select Sub-Category</option>
                    {
                      card1?.map((res,key)=>{
                        return(
                          <>
                          <option id={res.id} value={res.id} key={res.id}>{res.name}</option>
                          </>
                        )
                      })
                    }
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <select class="form-select form-select-lg" onChange={(e)=>{Market(e.target.value)}}>
            <option selected value="">Select District</option>
            {
                      card2?.map((res)=>{
                        return(
                          <>
                          <option value={res.id} id={res.id} key={res.id}>{res.name}</option>
                          </>
                        )
                      })
                    }

            </select>
          </div>
        </div>

        {
          card3?.length>0?
          card3.map((item)=>{
          return(
          <>
          <div className='row'>
          <div className='col-12 mt-2'>
          <NavLink to={`/Markets/${CatId}/${SubCatId}/${Dis}`}  style={{textDecoration:'none',color:'black'}}>
          <div className='card p-3'>
          <div className='row d-flex justify-content-between align-items-center'>
            <div className='col-7'>{item.name}</div>
            <div className='col-3' style={{color:'green'}}>₹ {item.rate}</div>
            <HiOutlineArrowNarrowRight className='col-2' />
          </div>
          </div>
          </NavLink>
          </div>
          </div>
          </>
          )
        }):<div>
                <div className='d-flex ' style={{justifyContent:"center",alignItems:"center"}}>
       <Lottie animationData={lotti} style={{width:"90%",height:"18rem",marginLeft:"0rem",}}/>
                  </div>
                {/* <h6 style={{color:"red"}}>No Data Found</h6> */}
                </div>
        }
      </div>

      <Bottombar />
    </>
  )
}

export default Market;