import React, { useEffect } from 'react'
import './Scraper.css'
// import logo from '../img/logo.png';
import logo from '../img/2ndlogo.png'
// import login from '../img/login.png'
import { NavLink, useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";
// import Bicycle from '../img/dastvin.json';
import Bicycle from '../img/data.json'


const Scraper = () => {
   
  const Navigate = useNavigate();

   
   useEffect(()=>{
     if(!localStorage.getItem("token")){

     }
     else{
      Navigate("/home");
     }
   },[])


  return (
  <>
<div style={{padding:'10px',overflowY:'hidden'}}>
<div style={{}}>
<img src={logo} className="App-logo" alt="logo" style={{width:300,}} />
</div>
<div>
<Lottie animationData={Bicycle} style={{ width:"100%",marginTop:'-11%'}}/>
</div>

<div style={{paddingLeft:'10px',}} className='mt-0'>
<p className='h1'>Scraper’s</p>
</div>
<div style={{paddingLeft:'10px',flexDirection:'row',}}>
<p className='app'>App </p>
<div style={{marginLeft:'20%',marginTop:'-6%',borderBottom:'1px solid black',width:'100px',}}></div>
</div>
<NavLink to="/Login">
  <button className="btnlogin" style={{border:'none',borderRadius:'8px'}}>Login</button>
</NavLink>

<NavLink to="/SignUp">
<button className='btnSignup' style={{borderRadius:'8px'}}>Signup</button>
</NavLink>
</div>

  </>
  )
}

export default Scraper