import React,{useState,useEffect} from 'react';
import '../componet/Home.css'
import { useNavigate } from 'react-router-dom';


const Profile = () => {

   
    const [card,setCard] = useState();


    console.log("hhhhh hhhh hhhh",card);

    const Navigate = useNavigate();

    const Profiles = async()=>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
  
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
  
        fetch("https://www.scraponwheels.com/api/app_scrapper/vendor_details", requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log("mmmm",result);
          if(result.status=="001"){
            setCard(result.data);
          }
          else if(result.status=="009"){
            Navigate("/");
          }})
          .catch(error => console.log('error', error));
      } catch (error) {
        
      }
    }

    useEffect(() => {
        Profiles();
    }, [])
    
     

    return (
        <>
            <div className="container-fulid" style={{width:'100%',height:'100vh',cursor:'pointer'}}>

                <div className="row">
                    <div className="col-12">
                      <img className="card-img-top" src="https://i.imgur.com/K7A78We.jpg" alt="Card image cap" />
                        <div className="card-body little-profile text-center">
                            <div className="pro-img">
                                <img src="https://i.imgur.com/8RKXAIV.jpg" alt="user" style={{cursor:'pointer'}} /></div>

                            <h3 className="m-b-0" style={{cursor:'pointer'}}>{card?.name}</h3>
                        </div>
                  
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 p-4" style={{ marginTop: '-25%',cursor:'pointer' }}>
                        <div className="card p-3" style={{ borderRadius: '8px',cursor:'pointer', background: '#040404' }}>
                            <div className="row">
                                <div className="col-12">
                                    <p className="list-group-item">Email <span style={{ float: 'right',cursor:'pointer' }}>{card?.email}</span></p>
                                    <hr style={{ color: '#fff', border: '1px solid white' }} />

                                    <p className="list-group-item"> Gst No. <span style={{ float: 'right' }}>{card?.gst}</span></p>
                                    <hr style={{ color: '#fff', border: '1px solid #fff' }} />

                                    <p className="list-group-item">Contact No.<span style={{ float: 'right' }}>{card?.mobile}</span></p>
                                    <hr style={{ color: '#fff', border: '1px solid #fff' }} />

                                    <div className="row">
                                    <div className="col-4">
                                    <p className="list-group-item">Location:</p>
                                    </div>
                                    <div className="col-8">
                                    <p className='list-group-item'>{card?.shop_address}</p>
                                    </div>
                                    </div>
                                  
                                    <hr style={{ color: '#fff', border: '1px solid #fff' }} />


                                    <p className='Myp'>My Wallet : ₹ {card?.wallet_bal}</p>
                                    <hr style={{ color: '#fff', border: '1px solid #fff' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
    </div>
        </>
    )
}

export default Profile