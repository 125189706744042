import React, { useEffect, useState } from 'react';
import '../componet/Bottom.module.css';
import '../componet/Home.css';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
// import lotti from '../img/animation_llaix5r2.json';
import lotti from '../img/data.json'
import Lottie from "lottie-react";
import { ToastContainer, toast } from 'react-toastify';

const OrderDetails = () => {

  const Navigate=useNavigate();

  let { order_id } = useParams();

  const navigate = useNavigate();
  const [card, setCard] = useState([]);
  const Apiadd = async () => {
    try {
      var formdata = new FormData();
      // formdata.append("token", localStorage.getItem("token"));
      formdata.append("order_id", order_id);
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/app_scrapper/orders_pending_details", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result);
          if (result.status == "001") {
            setCard(result.message);
          }
        })
        .catch(error => console.log('error', error));

    } catch (error) {
      console.log(error);
    }

  }
  
  const Complete = ()=>{
    var formdata = new FormData();
    formdata.append("order_id", order_id);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://www.scraponwheels.com/api/app_scrapper/order_mark_complete", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result)
        if(result.status=="001"){
           toast(result.message);
           Navigate("/Orders");
        }
        else{
          toast(result.message);
        }
      })
      .catch(error => console.log('error', error));
  }

  const Remove = ()=>{
    var formdata = new FormData();
    formdata.append("order_id", order_id);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://www.scraponwheels.com/api/app_scrapper/cancel_order", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result)
        if(result.status=="001"){
           toast(result.message);
           Navigate("/Home");
        }
        else{
          toast(result.message);
        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    Apiadd();
  }, []);
  return (
    <>
    <ToastContainer/>
      <div className="container">
        <div className="row mt-3">
          <div className="col-2 mt-3">
            <div onClick={() => navigate(-1)}>
              <i className="bi bi-chevron-left " style={{ border: '1px solid #EFEFEF', padding: '12px', borderRadius: '50px' }}></i>
            </div>
          </div>
          <div className="col-8">
            <p className='' style={{ fontSize: 30, textAlign: 'center' }}>Order Details</p>
          </div>
        </div>


        {
          card?.length > 0 ?
            card.map((inde) => {
              return (
                <>

                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="card" key={inde} style={{ width: '100%' }}>
                        <div className="card-body" style={{ background: "#f7f7f7", border: "none !important", }}>

                        <p className="Category">Order Initiated By <span style={{ color: 'red', backgroundColor: '#FFEDE8', fontSize: '14px', padding: '10px 20px 10px 20px', float: 'right', borderRadius: 10, }}>Pending</span></p>
                        <h6 className="card-subtitle mb-2">{inde.vendor_initiator}</h6>


                        <p className="Category">Order Initiator's Contact Number </p>
                        <h6 className="card-subtitle mb-2">{inde.vendor_initiator_mobile}</h6>

                        <p className="Category">Order Assigned To </p>
                        <h6 className="card-subtitle mb-1">{inde.vendor_assigned}</h6>

                        <p className="Category">Order Assign Contact Number </p>
                        <h6 className="card-subtitle mb-1">{inde.vendor_assigned_mobile}</h6>

                        <p className="Category">Estimated value</p>
                        <h6 className="card-subtitle mb-2">{inde.est_value} ₹</h6>

                        <p className="Category">Category </p>
                        <h6 className="card-subtitle mb-2">{inde.name}</h6>

                        <p className="Category">Sub-Category </p>
                        <h6 className="card-subtitle mb-2">{inde.sub_name}</h6>

                        <p className="Category">Weight </p>
                        <h6 className="card-subtitle mb-2">{inde.weight} {inde.si_unit}</h6>

                        <p className="Category">Pick Up Location  </p>
                        <h6 className="card-subtitle mb-2">{inde.shop_address}</h6>

                        <p className="Category">Pick Up Date</p>
                        <h6 className="card-subtitle mb-1">{inde.pickup_date}</h6>

                       
                        </div>
                      </div>
                    </div>
                  </div>



                </>
              )
            }) : <div>
              <div className='d-flex ' style={{ justifyContent: "center", alignItems: "center" }}>
                <Lottie animationData={lotti} style={{ width: "90%", height: "18rem", marginLeft: "0rem", }} />
              </div>
            </div>
        }


        <div className='mt-5'>
          <button className='Order' style={{background: "#808080", color: "#fff",}} onClick={()=>{Complete()}}>Mark as completed</button>

        </div>
        <div className='mt-3'>
          <button className='Order' style={{ backgroundColor: "#c41f1f", color: "#fff" }} data-bs-toggle="modal" data-bs-target="#exampleModal">Cancel Order</button>

        </div>


      </div>


      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
    <div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel">Do you really want to Cancle Order ?</h1>
   
   </div>
      <div class="modal-body">
      <div className='d-flex row'>
      <button type="button" class="btn btn-primary col-6" data-bs-dismiss="modal" onClick={()=>{Remove()}} style={{background:"#fff",color:"#000"}}>Yes</button>
      <button type="button" class="btn btn-primary col-6 "  data-bs-dismiss="modal"  style={{background:"#fff",color:"#000"}}>No</button>
      </div>
      </div>
     
    </div>
  </div>
</div>
    </>
  )
}

export default OrderDetails