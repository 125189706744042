import React, { useEffect, useState } from 'react'
import Header from './Header'
import Bottombar from './Bottombar'
import '../componet/Home.css';
import { HiOutlineArrowNarrowRight, HiArrowNarrowLeft } from 'react-icons/hi'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-bootstrap';

const Buyers = () => {

   const  {id,id1,id2} = useParams();

   const [card, setCard] = useState([]);
   const [card123, setCard123] = useState();
  const [SubCat, setSubCat] = useState();
  const [SubCatDis, setSubCatDis] = useState();
   const Navigate = useNavigate();

  
   

  const Market = async()=>{
    try {
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));
      formdata.append("category_id", id);
      formdata.append("subcategory_id", id1);
      formdata.append("district", id2);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/app_scrapper/market", requestOptions)
        .then(response => response.json())
        .then(result => {console.log("FGHJK FGHJCVB FGHGH",result);
        if(result.status=="001"){
            setCard(result.message);
            setCard123(result.subcategory.rate)
            setSubCat(result.subcategory.name);
            setSubCatDis(result.district.name);     
        }
        else if(result.status=="009"){
          Navigate("/");
        }
        else{
          toast(result.message);
        }
      })
        .catch(error => console.log('error', error));
    } catch (error) {
      
    }
  }
  
 useEffect(()=>{
  Market();
 },[]);
 
  return (
    <>
    <ToastContainer/>
      <Header />
      <div className='container' style={{ width: '100%', height: '80vh', overflow: 'scroll' }} >
        <div className="row mt-3">
          <div className="col-12 ">
            <div className="row">
              <div className="col-4 d-flex">
                <NavLink to={`/Market`} style={{ textDecoration: 'none', color: 'black', display: 'flex' }}>
                  <HiArrowNarrowLeft className='col-3 mt-2' />
                  <h3>Buyers</h3>
                </NavLink>
              </div>
              <div className="col-5">
                <button className='btn5 '>Section {SubCat}</button>
              </div>
              <div className="col-3">
                <button className='btn5'>{SubCatDis}</button>
              </div>
            </div>
          </div>

        </div>



        {card?.map((item) => {
          return (
            <>
              <div className='row mt-2'>
                <div className='col-12 mt-2'>
                  <div className='card p-3'>
                    <div className='row d-flex justify-content-between align-items-center'>
                      <div className='col-7'>{item.name}</div>
                      <div className='col-3' style={{ color: 'green' }}>₹ {item.rate}  </div>
                      <div className='col-2'>
                      <NavLink  to={`/Market/Buyers/${item.id}/${id1}/${id}`} style={{ textDecoration: 'none', color: 'black', display: 'flex' }}>
                      <HiOutlineArrowNarrowRight />
                      </NavLink>
                      </div>
                  
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        })}
      </div >

      <Bottombar />
    </>
  )
}

export default Buyers;