import React, { useEffect, useState } from 'react';
import Header from './Header';
import Bottombar from './Bottombar';
import { Link, NavLink, useNavigate } from 'react-router-dom';
// import lotti from '../img/animation_llaix5r2.json';
import lotti from '../img/data.json'
import Lottie from "lottie-react";
import { ToastContainer,toast } from 'react-toastify';
import Offcanvas from 'react-bootstrap/Offcanvas';


const Invoices = () => {
  const [card, setCard] = useState([]);
  const [Value, setValue] = useState();
  const [Value1, setValue1] = useState();
  const [TrnasId, setTrnasId] = useState();

  const Navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const Api = async () => {
    try {
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/app_scrapper/invoice_vendor_show", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log("", result);
          if (result.status == "001") {
            setCard(result.data);
          }
          else if (result.status == "009") {
            Navigate("/");
          }
        })
        .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error);
    }
  }



  const DashBoard = async () => {
    try {
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/app_scrapper/wallet_bal", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result);
          if (result.status == "001") {
            setValue(JSON.parse(result.data.wallet_bal));
          }
        })
        .catch(error => console.log('error', error));
    } catch (error) {

    }
  }



  const DashBoard1 = async () => {
    try {
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/app_scrapper/stock_value_show", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result);
          if (result.status == "001") {
            setValue1(JSON.parse(result.data.stock_value));
          }
        })
        .catch(error => console.log('error', error));
    } catch (error) {

    }
  }


  const Due = ()=>{
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    formdata.append("invoice_id", TrnasId);
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("https://www.scraponwheels.com/api/app_scrapper/invoice_vendor_due", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result)
      if(result.status=="001"){
       toast(result.message);
       Navigate("/Invoices");
       setTimeout(()=>{ Navigate("/Invoices");},1000);
       Api()
      }
      else{
        toast(result.message);
      }})
      .catch(error => console.log('error', error));
   
  }


  const Paid = ()=>{
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    formdata.append("invoice_id", TrnasId);
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("https://www.scraponwheels.com/api/app_scrapper/invoice_vendor_paid", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result)
      if(result.status=="001"){
       toast(result.message);
       setTimeout(()=>{Navigate("/Invoices");},1000);
       Api();
      }
      else{
        toast(result.message);
      }})
      .catch(error => console.log('error', error));
   
  }


  useEffect(() => {
    Api();
    DashBoard();
    DashBoard1();
  }, []);


  return (
    <>
    <ToastContainer/>
      <Header />
      <div className='container p-3'
        style={{ width: '100%', height: '84vh', overflow: 'scroll', backgroundColor: '#FBFBFB' }}>
        <div className='row'>
          <div className='col-6'>
            <h1 className='Invoices' > Invoice</h1>
          </div>
          <div className='col-6'>
            <Link to='/createInvoice'>
              <button className='btn2' style={{borderRadius:'8px', border:'none'}}> +  Create Invoice</button>
            </Link>
          </div>
        </div>


        <div className="row mt-4">
          <div className="col-12">
            <div className="card p-3">
              <h1 className='My_Wallet'>My Wallet : <b>₹ {Value}</b></h1>
             {/*<div className='row mt-4'>
                <div className='col-6'>
                  <button className='Deposit'>+ Deposit Money</button>
                </div>
                <div className='col-6'>
                  <button className='Withdraw'> Withdraw Money</button>
                </div>
              </div>*/} 
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 py-2">
            <div class="card" style={{ width: '100%' }}>
              <div class="card-body py-4">
                <h5 class="card-title">My Collection <span style={{ color:Value>=Value1?'rgba(0, 188, 132, 1)':"red", float: 'right' }}>₹ {Value>=Value1?(`+ ${Value-Value1}`):`${Value-Value1}`}</span></h5>
                <div className="row mt-3">
                  <div className="col-6">
                    <div className='' style={{ backgroundColor: 'rgba(242, 255, 249, 1)',borderRadius:'8px' }}>
                      <div className='ps-3' style={{ padding: '3px' }}>
                        <p className='in'>In</p>
                        <h4 className='inr'>₹ {Value}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className='ps-3' style={{ backgroundColor: 'rgba(255, 242, 242, 1)',borderRadius:'8px' }}>
                      <div className='' style={{ padding: '3px' }}>
                        <p className='in1 '>Out</p>
                        <h4 className='inr1 '>₹ {Value1}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-9">
            <h1 style={{ color: '#0000000', fontSize: '22px', fontFamily: 'Noto Sans', fontWeight: 'bold' }}>Transaction History</h1>
          </div>
          <div className="col-3 mt-1">
            <NavLink to={`/Invoices/${0}`} style={{ textDecoration: 'none', color: 'black' }}>
              <p style={{ color: '#6096FF', fontSize: '15px', fontFamily: 'Inter', }}> View All</p>

            </NavLink>
          </div>
        </div>

        {
          card?.length > 0 ?
            card.map((ind) => {
              return (
                <>
                <div className="row mt-2 justify-content-between align-items-center"  onClick={()=>{setTrnasId(ind.id);handleShow()}} style={{borderBottom:'1px solid #DCDCDC'}}>
                <div className="col-2">
                  <i class="bi bi-download"></i>
                </div>
                <div className="col-5">
                  <h5 style={{ fontSize: '16px', fontFamily: 'Noto Sans' }}>{ind.date}</h5>
                </div>
                <div className="col-5">
                  <p style={{ color:ind.status=="due"?"red":'#00BC84', marginLeft: '20%' }}>₹ {ind.amount}</p>
                </div>
              </div>
                </>
              )
            }
            ) : <div>
              <div className='d-flex ' style={{ justifyContent: "center", alignItems: "center" }}>
                <Lottie animationData={lotti} style={{ width: "90%", height: "18rem", marginLeft: "0rem", }} />
              </div>
              {/* <h6 style={{color:"red"}}>No Data Found</h6> */}
            </div>
        }
      </div>

     
      <Offcanvas show={show} onHide={handleClose} placement='bottom'>
      <Offcanvas.Header closeButton>
    
        <Offcanvas.Title></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
      <div className='row'>
      <div className='col-12'>
      <button type="button" class=" btn-primary"  data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-dismiss="offcanvas" aria-label="Close" style={{width:"100%",padding:"10px",background:"#3caa4a",fontSize:"24px",fontWeight:"500",borderRadius:"13px",border:"none",color:"#fff"}} onClick={()=>{handleClose()}}>Mark as Paid</button>
      </div>
      <div className='col-12 mt-4'>
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal1" data-bs-dismiss="offcanvas" aria-label="Close" style={{width:"100%",padding:"10px",background:"rgb(223 70 70)",fontSize:"24px",fontWeight:"500",borderRadius:"13px",border:"none",}} onClick={()=>{handleClose()}}>Mark as Due</button>
        </div>
    </div>
      </Offcanvas.Body>
    </Offcanvas>



      {/* end offcanvas */}
      
      {/* modal */}
      {/* Button trigger modal */}
      {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
      Launch demo modal
      </button> */}
      
      {/* Modal */}
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Do you really want to Mark as Paid ?</h1>
         
         </div>
            <div class="modal-body">
            <div className='d-flex row'>
            <button type="button" class="btn btn-primary col-6" data-bs-dismiss="modal" onClick={()=>{Paid()}} style={{background:"#fff",color:"#000"}}>Yes</button>
            <button type="button" class="btn btn-primary col-6 "  data-bs-dismiss="modal"  style={{background:"#fff",color:"#000"}}>No</button>
            </div>
            </div>
           
          </div>
        </div>
          </div>
      
      
          <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Do you really want to Mark as Due ?</h1>
         
         </div>
            <div class="modal-body">
            <div className='d-flex row'>
            <button type="button" class="btn btn-primary col-6" data-bs-dismiss="modal" onClick={()=>{Due()}} style={{background:"#fff",color:"#000"}} >Yes</button>
            <button type="button" class="btn btn-primary col-6 "  data-bs-dismiss="modal"  style={{background:"#fff",color:"#000"}}>No</button>
            </div>
            </div>
           
          </div>
        </div>
          </div>

      <Bottombar />
    </>
  )
}

export default Invoices


