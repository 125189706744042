import React, { useState } from 'react';
import '../componet/Scraper.css';
// import logo from '../img/logo.png';
import logo from '../img/2ndlogo.png'
import { NavLink } from 'react-router-dom';
import Lottie from "lottie-react";
// import Bicycle from '../img/dastvin.json';
import Bicycle from '../img/data.json'
import { FiArrowRight } from 'react-icons/fi';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  // import { navigate } from '@reach/router';
  import {  useNavigate } from 'react-router-dom';
  import OTPInput, { ResendOTP } from "otp-input-react";


  

  
export default function OTP() {
  const [isActive, setIsActive] = useState(false);
  const [OTP,setOTP]= useState(""); 


  const navigate = useNavigate()
  //const [otps, setOtp] = useState('');
  const handleInputChange = (e) => {
    const {value} = e.target;
    // setIsActive(value.length > 0);
    setOTP(value);
  };

  const validateOTP =(otp)=>{
    const mobile = localStorage.getItem('mobile')
    // console.log(mobile,otp,"error");
    
    var formdata = new FormData();
    formdata.append("mobile", mobile);
    formdata.append("otp", OTP);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://www.scraponwheels.com/api/app_scrapper/otp_validate", requestOptions)
    .then(response => response.json())
    .then(result => {
    
        console.log(result)
      if (result.status == "001" ){
        localStorage.setItem('signtoken',result.token);
        navigate('/OtpSuccec');
      }
      else {
        toast(result.message);
      }
    
})
.catch(error => console.log('error', error));
};



 
  return (
    <>
      <div style={{ padding: '13px' }}>
        <ToastContainer/>
        <div style={{ padding: 10 }}>
          <img src={logo} className="App-logo" alt="logo" style={{ width: 300 }} />
        </div>
        <div>
          <Lottie animationData={Bicycle} style={{ width: "100%", marginTop: '-12%' }} />
        </div>

        <div style={{ paddingLeft: '10px' }} className='mb-2'>
          <p className='loginh1'>Enter OTP</p>
          <p style={{ fontSize: '16px' }}>OTP Sent to +91 {localStorage.getItem('mobile')}</p>
        </div>
        <div style={{ paddingLeft: '10px', flexDirection: 'row' }}>
          <NavLink to="/SignUp" style={{textDecoration:'none'}}><p style={{ fontSize: '14px', color: '#00A56A' }}>Change Number</p> </NavLink>


          {/* <ResendOTP onResendClick={()=>{addVender()}} style={{  padding:'2px 10px'}} /> */}
        
        </div>

        <div style={{marginTop:20,marginBottom:20}}>
        <OTPInput style={{width:"100%"}}  inputStyles={{width:"25%",borderBottom:"1px solid #000",borderTop:0,borderLeft:0,borderRight:0}} value={OTP} onChange={setOTP}  OTPLength={4} otpType="number" disabled={false}  />
        </div>
         


         {
          OTP.length == 4?<><button onClick={()=>validateOTP(OTP)} className="Proceed">Proceed  <i class="bi bi-arrow-right" style={{fontSize:'22px'}}></i></button>
          </>
          :
          <><button   className="Proceed" style={{backgroundColor:'#bbbbbb', color:'#000', cursor:'not-allowed'}}> Enter Otp  <i class="bi bi-arrow-right" style={{fontSize:'22px'}}></i></button>
          </>
         }
        
        
      </div>
    </>
  );
}
