import React, { useState } from 'react';
import '../componet/Scraper.css';
// import logo from '../img/logo.png';
import logo from '../img/2ndlogo.png';
import { NavLink } from 'react-router-dom';
import Lottie from "lottie-react";
// import Bicycle from '../img/dastvin.json';
import Bicycle from '../img/data.json'
import { FiArrowRight } from 'react-icons/fi';

export default function OtpSuccec() {
    return (
        <>


            <div className="container" style={{overflowX:'hidden'}}>
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8 mt-3">
                        <img src={logo} className="App-logo" alt="logo" style={{ width: '100%' }} />
                    </div>

                </div>

                <div className="row">
                    <div className="col-12 p-4">
                        <div style={{marginTop:'-10%'}}>
                            <Lottie animationData={Bicycle} style={{ width:"100%", }} />
                        </div>
                        <p style={{ fontSize: '30px',fontWeight:'600' }}>OTP Verification has been successful !</p>
                        <p style={{ fontSize: '22px',color:'black',fontWeight:'600'  }}>{localStorage.getItem('mobile')}</p>

                        <p style={{ fontSize: '17px', color: '#0000000' }}>Will be used as primary contact number. You can change it later</p>

                        <NavLink to="/Register">
                           <button className="Proceed mt-5">Proceed  <i class="bi bi-arrow-right" style={{ fontSize: '22px' }}></i></button>
                       </NavLink>
                    </div>
                    
                </div>
            </div>
        </>
    );
}
