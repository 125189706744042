import React, { useEffect, useState } from 'react'
import Header from './Header'
import Bottombar from './Bottombar';
import '../componet/Home.css'
import { NavLink, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
// import lotti from '../img/animation_llaix5r2.json';
import lotti from '../img/data.json'
import Lottie from "lottie-react";

const InventoryView = () => {
    const navigate = useNavigate();

    const [card,setCard]=useState([]);
    const [CatId,setCatId]=useState("");
    const [Card1,setCard1]=useState([]);
    const [SubCat,setSubCat]=useState("");
    const [SubCatName,setSubCatName]=useState();
    const [Stock,setStock]=useState();
    const [Rate, setRate] = useState();

    const catgApi = ()=>{
        var myHeaders = new Headers();
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch("https://www.scraponwheels.com/api/app_scrapper/category_fetch", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
            if(result.status=="001"){
              setCard(result.message);
            }
            else if(result.status=="009"){
              navigate("/");
            }
            else{
              console.log("error");
            } 
          })
          .catch(error => console.log('error', error));
       }


    const subcatgApi = async(id)=>{

        setCatId(id);
        setCard1([]);
        var formdata = new FormData();
        
        formdata.append("token", localStorage.getItem("token"));
        formdata.append("category_id", id);
        var requestOptions = {
          method: 'POST',
      body: formdata,
      redirect: 'follow'
        };
        
        fetch("https://www.scraponwheels.com/api/app_scrapper/get_inventory_details", requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result);
            if(result.Status=="001"){
              setCard1(result.Details );
            }
            else{
              console.log("error");
            } 
          })
          .catch(error => console.log('error', error));
       }



    //  const  Add = async()=>{
    //     try {
    //         var formdata = new FormData();
    //         formdata.append("token", localStorage.getItem("token"));
    //         formdata.append("weight", Stock);
    //         formdata.append("category_id", CatId);
    //         formdata.append("subcategory_id", SubCat);

    //         var requestOptions = {
    //         method: 'POST',
    //         body: formdata,
    //         redirect: 'follow'
    //         };

    //         fetch("https://www.scraponwheels.com/api/app_scrapper/add_inventory", requestOptions)
    //         .then(response => response.json())
    //         .then(result => {console.log(result);
    //             if(result.status=="001"){
    //               toast(result.message)
    //               setTimeout(()=>{
    //                  navigate("/Inventory");
    //               },3000)
    //             }
    //             else{
    //                 toast(result.message);
    //             }
    //         })
    //         .catch(error => console.log('error', error));
    //     } catch (error) {
            
    //     }
    //  }

     
     
  useEffect(()=>{
    catgApi();
  },[])



    return (
        <>
        <ToastContainer/>
        
            <Header />

            <div className='container p-3' style={{ width: '100%', height: '80vh', overflow: 'scroll', backgroundColor: '#FBFBFB' }}>
                <div className='row mt-3'>
                    <div className="col-12">
                        <h1 className='Inventory'>Select Category</h1>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <select className="form-select form-select-lg" onChange={(e)=>{subcatgApi(e.target.value)}}>
                            <option>Select Category  </option>
                            {
                                card?.map((res,key)=>{
                                    return(
                                        <>
                                        <option value={res.id}>{res.name}</option>
                                        </>
                                    )
                                })
                            }

                        </select>
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className="col-12">
                    {
                      Card1.length>0?
                        Card1?.map((res,key)=>{
                            return(
                                <>
                                
                                <div className="card p-3 mt-3">

                                <div onClick={()=>{setSubCat(res.id);setSubCatName(res.name)}}>
                                <p className='' style={{ borderRadius: '10px', border: '1px solid #CDFBE7', background: '#F6FFFB', width: 170, padding: '10px' }}>{res.name}</p>
                                </div>


                                <h4 className='Amount mt-3'>Amount <span style={{ float: 'right',color:'red',fontSize:'16px' }}>₹ {res.amount}</span></h4>
                                 <h4 className='Amount mt-3'>Weight   <span style={{ float: 'right',color:'red',fontSize:'16px' }}> {res.weight} {res.si_unit} </span></h4>
                                </div>
                            </>
                            )
                        }):<><div>
                        <div className='d-flex ' style={{ justifyContent: "center", alignItems: "center" }}>
                          <Lottie animationData={lotti} style={{ width: "90%", height: "18rem", marginLeft: "0rem", }} />
                        </div>
                        </div></>
                    } 
                       
                    </div>
                </div>


                <Bottombar />
            </div>



            {/* <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                <p className='' style={{ borderRadius: '10px', border: '1px solid #CDFBE7', background: '#F6FFFB', width: 170, padding: '10px' }}>{SubCatName}</p>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="container">
                <h3>Enter Rate</h3>
                <div className="col-6 ms-1">
                <input type="text"  style={{padding:'20px',width:'100%',margingLeft:'40px',borderRadius:'5px'}} onChange={(e)=>{setRate(e.target.value)}}/>
                </div>
                <div className="row justify-content-between align-items-center">
               
                
                <h3>Enter Stock</h3>
                <div className="col-6 ms-1">
                <input type="text"  style={{padding:'20px',width:'100%',margingLeft:'40px',borderRadius:'5px'}} onChange={(e)=>{setStock(e.target.value)}}/>
                </div>
                <div className="col-5 mt-3">
                <p type="text" placeholder=''  style={{padding:'20px',width:'100%',border:'1px solid #000',borderRadius:'5px'}}>Kilograms</p>
                </div>
                <button className='AddStock mt-3' onClick={()=>{Add()}} data-bs-dismiss="modal" aria-label="Close">Add Stock</button>
                </div>
                </div>
                
                </div>
                 </div>
            </div>
          </div>
           */}
        </>
    )
}

export default InventoryView