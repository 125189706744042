import React, { useEffect, useState } from 'react';
import '../componet/Bottom.module.css';
import '../componet/Home.css';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
// import pro from '../img/prod.png';
import {BiCloudDownload} from 'react-icons/bi'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

const OrderDetails1 = () => {


    const {order_id} = useParams();
    const navigate = useNavigate();
    const [card, setCard] = useState([]);
    const [card1, setCard1] = useState("");
    const [card2, setCard2] = useState(""); 


    pdfMake.vfs = pdfFonts.pdfMake.vfs;
 

   //const [Img, setImg] = useState('https://lms.tipsg.in/storage/uploads/preferences/20230701122817.png');

   const getImageDataUrl = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const generatePDF = async() => {

    const docDefinition = {
      content: [
        
       {
        text: 'Completed', 
        style: 'headers1',
      }, 
        {
          text: 'Total Order Values :', 
          style: 'subheader',
        },

        {
          columns: [
            {
              width: '40%',
              text: `₹ ${card1}`,
            }, 
            // {
            //   width: '40%',
            //   text: 'Date Picked up : 01/07/2023',
            // },
          ],
        },
        {
          text: `Date Picked up : ${card2}`,
        },
        
        {
          text: '', // Total amount section
          alignment: 'right',
        }, 

        {
          text:'Category', 
          style:'Category'
        },
        {
          text:`${card[0]?.name}`, 
          style:'Electronics' 
        },
        {
          text:'Sub-Category', 
          style:'SubCategory' 
        },
        {
          text:`${card[0]?.sub_name}`, 
          style:'Television' 
        },
        {
          text:'Actual Weight', 
          style:'SubCategory' 
        },
        {
          text:`${card[0]?.weight} Kilograms`, 
          style:'Television' 
        },
        {
          text:'Price', 
          style:'SubCategory' 
        },
        {
          text:`₹ ${card[0]?.rate}/kg`, 
          style:'Television' 
        },
        {
          text:'Total Price', 
          style:'SubCategory' 
        },
        {
          text:`₹ ${card[0]?.item_total}`, 
          style:'Television' 
        },
        {
          text:'Pick Up Location', 
          style:'SubCategory' 
        },
        {
          text:`${card[0]?.shop_address}`, 
          style:'Television' 
        },
        {
          text:`Date Picked up :${card2}`, 
          style:'Date' 
        },

      ],
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          alignment: 'right',
          margin: [0, 20, 0, 0],
        },
        headers:{
          fontSize: 10, 
          bold: true,
          color:"green",
          alignment: 'right',
          margin: [0, 0, 0, 0],
        },
        subheader: {
          fontSize: 13,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        bottoms: {
          fontSize: 14,
          bold: true,
          alignment: 'end',
          margin: [0, 100, 0, 5],
        },
        Category:{
          color:'gray',
          marginTop:30,
          fontSize:10

        },
        Electronics:{
          color:'black',
          marginTop:7,
          fontSize:14
        },
        SubCategory:{
          color:'gray',
          marginTop:10,
          fontSize:10
        },
        Television:{
          color:'black',
          marginTop:5,
          fontSize:14
        },
        Date:{
          color:'black',
          marginTop:15,
          fontSize:13
        },
        
        headers1:{
          marginTop:5,
          fontSize: 10, 
          bold: true,
          color:"green",
          alignment: 'right',
          margin: [0, 10, 0, 5],
        }
      },
    };

    pdfMake.createPdf(docDefinition).open();
  };


    const Apiadd = async() =>{
      try {
        var formdata = new FormData();
        // formdata.append("token", localStorage.getItem("token"));
        formdata.append("order_id", order_id);
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        
        fetch("https://www.scraponwheels.com/api/app_scrapper/orders_completed_details", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
            if(result.status=="001"){
              setCard(result.order_details);
              setCard1(result.total_amount);
              setCard2(result.pickedup_date)
            }
           })
          .catch(error => console.log('error', error));
        
      } catch (error) {
        console.log(error);
      }
      
    }
  
  
    useEffect(()=>{
      Apiadd();
    },[])
    return (
        <>
            <div className="container">
                <div className="row mt-3">
                    <div className="col-2 mt-3">
                        <div onClick={() => navigate(-1)}>
                            <i className="bi bi-chevron-left " style={{ border: '1px solid #EFEFEF', padding: '12px', borderRadius: '50px' }}></i>
                        </div>
                    </div>
                    <div className="col-8">
                        <p className='' style={{ fontSize: 30, textAlign: 'center' }}>Order Details</p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="card"  style={{ width: '100%', borderRadius: 10 }}>
                            <div className="card-body">
                                <p className="Category">Total Order Value <span style={{ color: '#00A56A', fontSize: '16px', float: 'right', borderRadius: 10, }}>Completed</span></p>
                                <h6 className="odervalue mb-2">₹ {card1}</h6>
                                <h6 className="cardDate mb-2">Date Picked up :{card2}</h6>
                            </div>
                        </div>
                    </div>
                </div>

                {
            
            card?.map((inde) => {
              return (
                <>
                


                <div className="row mt-3">
                    <div className="col-12 ">
                        <div className="card " key={inde} style={{ width: '100%', borderRadius: 10 }}>
                           
                            <div className="card-body">

                                <p className="Category">Category </p>
                                <h6 className="card-subtitle mb-2">{inde.name}</h6>


                                <p className="Category">Sub-Category </p>
                                <h6 className="card-subtitle mb-2">{inde.sub_name}</h6>


                                <p className="Category">Actual Weight </p>
                                <h6 className="card-subtitle mb-2">{inde.weight} {inde.si_unit}</h6>

                                <p className="Category">Price </p>
                                <h6 className="card-subtitle mb-2">₹ {inde.rate}</h6>


                                <p className="Category">Total Price </p>
                                <h6 className="card-subtitle mb-2">₹ {inde.item_total}</h6>


                                <p className="Category">Pick Up Location </p>
                                <h6 className="card-subtitle mb-2">{inde.shop_address}</h6>


                                <h6 className="cardDate mb-2">Date Picked up :{inde.pickup_date}</h6>
                            </div>
                        </div>
                    </div>
                </div>

                </>
              )
            })
          }
                <div className='row mt-3'>
                 <div className='col-12'>
                  <button type='button' onClick={generatePDF} className='d-flex Orderdow'>  Download Reciept   <i className="bi bi-cloud-arrow-down ms-3" ></i></button>
                 </div>
                </div>
        </div>
        </>
    )
}

export default OrderDetails1