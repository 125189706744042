import React, { useState } from "react";
import "../componet/Scraper.css";
// import logo from "../img/logo.png";
import logo from "../img/2ndlogo.png"
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import SingUpValid from "./V";
import { toast } from "react-toastify";

export default function PersonalDe() {
  const navigate = useNavigate()
  // const [selectedCountryCode, setSelectedCountryCode] = useState('');
  // const countryCodes = ['+91', '+23'];

  // const handleCountryCodeChange = (event) => {
  //   setSelectedCountryCode(event.target.value);
  // };
  const register = (values) => {
    var formdata = new FormData();
    Object.entries(values).map(([key, value]) => formdata.append(key, value));
    formdata.append("token", localStorage.getItem("token"));

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://www.scraponwheels.com/api/app_scrapper/vendor_personal",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) =>
      {
        if ( result.status == "001"){
          navigate('/ShopDe')
        }
        else if(result.status=="009"){
          navigate("/");
        }
        else {
          toast(result.message)
        }
        console.log(result)
      }
      )
    
      .catch((error) => console.log("error", error));
  };

  const initialValues = {
    name: "",
    email: "",
    // number: '',
  };

  const formik = useFormik({
    initialValues: initialValues,

    validationSchema: SingUpValid,

    onSubmit: (values) => {
      console.log("clicked in sub");
      register(values);
      // alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <>
      <div className="container" style={{ overflowX: "hidden" }}>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 mt-3">
            <img
              src={logo}
              className="App-logo"
              alt="logo"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-4">
            <p
              style={{
                fontSize: "26px",
                fontFamily: "Inter",
                fontWeight: "600",
              }}
            >
              Enter Personal Details{" "}
            </p>
            <div>
              <p style={{ fontSize: "14px", fontFamily: "Inter" }}>
                Registering As:{" "}
                <span style={{ color: "rgba(234, 89, 50, 1)" }}>
                  {" "}
                  Scrap Collector
                </span>{" "}
                <span
                  style={{
                    float: "right",
                    color: "#6096FF",
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontWeight: "500",
                  }}
                >
                  change
                </span>{" "}
              </p>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  <i className="bi bi-person-fill"></i> Enter Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name..."
                  autoComplete="off"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.name && formik.touched.name ? (
                  <p className="form-d">{formik.errors.name}</p>
                ) : null}
              </div>
              <div className="mb-3 mt-5">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  {" "}
                  <i className="bi bi-envelope-fill"></i> Enter Email
                </label>

                <input
                  type="Email"
                  className="form-control"
                  id="email"
                  placeholder="Email..."
                  autoComplete="off"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email ? (
                  <p className="form-d">{formik.errors.email}</p>
                ) : null}
              </div>
              {/* <div className="mb-3 mt-5">
                <label htmlFor="exampleInputPassword1" className="form-label"> <i className="bi bi-telephone-fill"></i>   Enter Contact No.</label>

                <div className="row">
                  <div className="col-12">
                    <div style={{ width: '100%', margin: '10px 0', backgroundColor: 'white' }}>
                      <div style={{ display: 'flex' }}>
                        <div>
                          <select 
                            value={selectedCountryCode}
                            onChange={handleCountryCodeChange}
                            style={{ fontSize: '16px', padding: '16px 5px 16px 5px', borderRadius: '10px',marginRight:5 }}
                          >
                            {countryCodes.map((value) => (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                        </div>
                        <input
                          type="text"
                          required
                          pattern="[0-9]*"
                          autoComplete='off'
                          name='number'
                          value={values.number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            flex: 1,
                            padding: '12px',
                            border: '1px solid hsl(0, 2%, 49%)',
                            borderRadius: '10px',
                            backgroundColor: 'white',
                          }}
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                  </div>
                  { errors.number && touched.number ? (<p className='form-d'>{errors.number}</p>):null}
                </div>
              </div> */}
              <div className="form-group">
                {/* <NavLink to='/ShopDe'> */}
                <button className="Proceed mt-2 btn-block" type="submit">
                  {" "}
                  Proceed{" "}
                  <i
                    className="bi bi-arrow-right"
                    style={{ fontSize: "22px" }}
                  ></i>
                </button>
                {/* </NavLink> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
