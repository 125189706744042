import React from "react";
import Lottie from 'react-lottie-player'
// import lottieJson from '../img/Congres.json'
import lottieJson from '../img/data.json'
import { NavLink, useParams } from "react-router-dom";



const SuccessFull = () =>{

    const {name,add} = useParams()

    return(
        <>
        <div className="container mt-">
            <div className="row">
               <div className="col-lg-7 m-auto">

                <div>
                <Lottie
                        loop
                        animationData={lottieJson}
                        play
                        style={{ width: '100%', height: 'auto' }}
                        />
                </div>

                       <h1 className="mb-4" style={{textAlign:'center'}}> Congratulations </h1>

                       <h5 className="my-5" style={{textAlign:'center'}}>Your Scrap Has been Successfully Sold To <span style={{fontWeight:'600', fontSize:'22px'}}> {name} </span> At {add}</h5>


                       <p style={{color:'red', textAlign:'center', fontSize:'14px'}}>Deliver Scrap to Vendor's Location Within 24 Hours</p>


                <div style={{display:'flex', justifyContent:'center'}}>

                   <NavLink to="/"> <button style={{backgroundColor:'transparent', padding:'8px 22px' , borderRadius:'8px',}}>Back to Home </button>
                   </NavLink>
                </div>
                </div> 
            </div>
        </div>
        </>
    )
}

export default SuccessFull;