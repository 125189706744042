import React, { useEffect, useState } from 'react';
import Bottombar from './Bottombar';
import { CgCalendarDates } from 'react-icons/cg';
import '../componet/Bottom.module.css'
import { NavLink, useNavigate } from 'react-router-dom';
// import lotti from '../img/animation_llaix5r2.json';
import lotti from '../img/data.json'
import Lottie from "lottie-react";

export default function Completed() {

  const [card, setCard] = useState([]);
   
  const Navigate = useNavigate();

  
  const Apipending = ()=>{
    try {
      var formdata = new FormData();
      
      formdata.append("token",localStorage.getItem("token"));

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/app_scrapper/orders_pending", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result);
        if(result.status=="001"){
          setCard(result.message);
        }
        else if(result.status=="009"){
          Navigate("/");
        }
        else{
          
        }
      }
        )
        .catch(error => console.log('error', error));
    } catch (error) {
      
    }
  }


 

  const data = [

    {
      name: 'sonu yadav',
      nameE: ' Estimated Value',
      price: ' ₹ 45,000',
      location: 'Pickup Location : 302039',
    },

    {
      name: 'kaju yadav',
      nameE: ' Estimated Value',
      price: ' ₹ 45,000',
      location: 'Pickup Location : 302039',
    },

    {
      name: 'vasu yadav',
      nameE: ' Estimated Value',
      price: ' ₹ 45,000',
      location: 'Pickup Location : 302039',
    },

    {
      name: 'vinod yadav',
      nameE: ' Estimated Value',
      price: ' ₹ 45,000',
      location: 'Pickup Location : 302039',
    },


  ]
useEffect (()=>{
  Apipending();
  },[])
  return (
    <>

      <div className="container mt-3  mb-5 pb-4" style={{ width: '100%', height: '80vh', overflow: 'scroll' }}>
        <div className="row p-2 d-flex">
          {
            card?.length>0?
            card.map((inde) => {
              return (
                <>
                  <div className="col-12 d-flex justify-content-center align-items-center">

                    <div className="card mt-3" key={inde} style={{ width: '25rem' }}>
                      <div className="card-body">
                        <h6 className="cardname "> <i class="bi bi-person-fill pe-2"></i>{inde.name}</h6>
                        <h6 className="cardtext "> {inde.nameE}</h6>
                        <h4 className="cardtext ">Estimated Value: ₹ {inde.est_value}</h4>
                        <p className="card-text "> <i class="bi bi-calendar3"></i>    Pickup Date : <span style={{ color: 'red' }}>{inde.pickup_date}</span></p>
                        <p className="card-text "> <i class="bi bi-geo-alt-fill"></i> location:  {inde.shop_address}</p>
                        <NavLink to={`/OrderDetails/${inde.order_id}`} style={{textDecoration:'none'}}>
                          <button className='btn6 ' >View Details</button>
                        </NavLink>
                      </div>
                    </div>
                  </div>

                </>
              )
            }):<div>
                <div className='d-flex ' style={{justifyContent:"center",alignItems:"center"}}>
       <Lottie animationData={lotti} style={{width:"90%",height:"18rem",marginLeft:"0rem",}}/>
                  </div>
                {/* <h6 style={{color:"red"}}>No Data Found</h6> */}
                </div>
          }
        </div>
      </div>

      <Bottombar />
    </>
  );
}
