import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import { NavLink } from 'react-router-dom';
import styles from './Bottom.module.css';

export default function Bottombar() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels // Set showLabels to true
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: '0px',
        right: '0px',
        color: '#767676',
        boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.1)',
        padding: '0.2rem 30px 5px 30px', 
      }}
    >
      <NavLink exact to="/Home" className={({isActive}) => isActive ? styles.activeStyle : styles.defaultStyle}>
        <BottomNavigationAction
          showLabel={true} 
          label={<span className={styles.labelText}>Home</span>} 
          icon={<HomeIcon />}
        />
      </NavLink>
      <NavLink exact to="/Market" className={({isActive}) => isActive ? styles.activeStyle : styles.defaultStyle}>
        <BottomNavigationAction
          showLabel={true} 
          label={<span className={styles.labelText}>Market</span>} 
          icon={<LocalOfferOutlinedIcon />}
        />
      </NavLink>
      <NavLink exact to="/Orders" className={({isActive}) => isActive ? styles.activeStyle : styles.defaultStyle}>
        <BottomNavigationAction
          showLabel={true} 
          label={<span className={styles.labelText}>Orders</span>} 
          icon={<WorkOutlineOutlinedIcon />}
        />
      </NavLink>
      <NavLink exact to="/Invoices" className={({isActive}) => isActive ? styles.activeStyle : styles.defaultStyle}>
        <BottomNavigationAction
          showLabel={true} 
          label={<span className={styles.labelText}>Invoices</span>} 
          icon={<ReceiptIcon />}
        />
      </NavLink>
      <NavLink exact to="/Inventory" className={({isActive}) => isActive ? styles.activeStyle : styles.defaultStyle}>
        <BottomNavigationAction
          showLabel={true} 
          label={<span className={styles.labelText}>Inventory</span>} 
          icon={<InventoryIcon />}
        />
      </NavLink>
    </BottomNavigation>
  );
  
  
}
